import React, { useState, useEffect } from 'react';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Button,
    Subtitle1,
} from "@fluentui/react-components";
import {
    EditRegular,
    DeleteRegular,
    BookContactsRegular,
} from "@fluentui/react-icons";
import { useMsal } from '@azure/msal-react';

import LoadingPage from './LoadingPage';
import { useAuthHeader } from './getAuthHeader';
import { useSignInState } from './signInState';
import InviteTable from './InviteTable';

interface InvitedTableProps {
    onInviteUser: (inviteUser: boolean) => void;
    CustomerID: number | undefined;
}

const InvitedUserTable: React.FC<InvitedTableProps> = ({ onInviteUser, CustomerID }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [invites, setInvites] = useState([]);
    const [inviteUser, setInviteUser] = useState<Boolean>(false);
    const [hasInvites, setHasInvites] = useState(false);
    const [isPending, setIsPending] = useState(true);

    const { signInState } = useSignInState();
    const [authHeader, setAuthHeader] = useState({});
    const { inProgress, accounts } = useMsal();

    const getAuthHeader = useAuthHeader('AdminUser', 'POST');

    useEffect(() => {
        const fetchAuthHeader = async () => {
            const header = await getAuthHeader();
            setAuthHeader(header);
        };
        if (inProgress === "none" && accounts.length > 0) {
            fetchAuthHeader();
        }
    }, [inProgress, accounts]);

    useEffect(() => {
        // Fetch users based on the CustomerID from the /getusers API
        function isRequestInit(obj: any): obj is RequestInit {
            return obj && typeof obj === 'object' && 'method' in obj && 'headers' in obj
        }
        if (isRequestInit(authHeader)) {
            fetch(`${apiUrl}admin/getinvitedetails`, {
                ...authHeader,
                body: JSON.stringify({ "UserID": signInState.userInfo[0].UserID, "CustomerID": CustomerID })
            })
                .then(response => response.json())
                .then(data => {
                    console.log('[GetInvitedUsers] Invited Users:', data);
                    if (data.result.length === 0) {
                        console.log('No invites found for the CustomerID:', CustomerID);
                        setHasInvites(false);
                        setIsPending(false);
                    } else {
                        setHasInvites(true);
                        setInvites(data.result);
                        setIsPending(false);
                    }

                })
                .catch(error => console.error(error));

        }
    }, [authHeader, CustomerID]);

    if (isPending) {
        return (
            < LoadingPage message="Loading Invites..." />
        );
    }
    else {
        if (hasInvites) {
            return (
                <div style={{ margin: '20px' }}>
                    <Button icon={< BookContactsRegular />} onClick={() => onInviteUser(true)} style={{ marginBottom: '20px' }}>Invite new user</Button>
                    <div>
                    < InviteTable invites={invites} />
                    </div>
                </div>

            );
        }
        if (!hasInvites) {
            console.log("[GetInvitedUsers]: Invites:", hasInvites);
            return (
                <div style={{ margin: '20px' }}>
                    <Button icon={< BookContactsRegular />} onClick={() => onInviteUser(true)} style={{ marginBottom: '20px' }}>Invite new user</Button>
                    <p/>
                    <Subtitle1>It's lonely in here, invite someone to join you...</Subtitle1>
                </div>
            );
        }
    };
    return null;
};

export default InvitedUserTable;