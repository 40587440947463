import { InteractionRequiredAuthError, AuthError } from "@azure/msal-browser";

const authErrorHandling = (error: any): string => {
    // Extract error code and message from the error object
    const errorCode = error.errorCode || error.name; // MSAL errors should have an errorCode
    const errorMessage = error.errorMessage || error.message; // Try to capture a descriptive error message

    let userErrorMessage = '';

    if (error instanceof InteractionRequiredAuthError) {
        console.log('[authErrorHandling] InteractionRequiredAuthError:');
        // This error occurs when the user or administrator needs to interact with the Azure AD authorization endpoint.
        // This can happen when the user needs to authenticate, or when the user needs to grant consent.

        if (errorCode === "consent_required" && errorMessage.includes("AADSTS65004")) {
            console.error("[authErrorHandling] User declined to consent to access the app.");
            userErrorMessage = "User declined the consent for the app. (ADSTS65004)";
        }
        else {
          console.log('[authErrorHandling] InteractionRequiredAuthError not handled:', errorCode, errorMessage);
          userErrorMessage = errorMessage;
        }

      } else if (error instanceof AuthError) {
        console.log('[authErrorHandling] AuthError:');
        // This is a general authentication error. It can occur for various reasons, such as network errors, configuration errors, or Azure AD errors.

        if (errorCode === "user_cancelled") {
            console.error("[authErrorHandling] User cancelled the flow.");
            userErrorMessage = "User cancelled the flow.";
        }
        else if (errorCode === "access_denied" && errorMessage.includes("AADSTS65004")) {
          console.error("[authErrorHandling] User declined to consent to access the app or had to request admin approval.");
          userErrorMessage = "User declined the consent for the app or had to request admin approval. (ADSTS65004)";
        }
        else {
          console.log('[authErrorHandling] AuthError not handled:', errorCode, errorMessage);
          userErrorMessage = errorMessage;
        }

      } else {
        // This is an unexpected error. It might be a bug in the code, or an unhandled edge case.
        console.error("[authErrorHandling] Erm... Yeah, something went wrong.");
        userErrorMessage = "Erm... Yeah, something went wrong. Sorry??";
      }

    return userErrorMessage;
}

export default authErrorHandling;



