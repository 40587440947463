import { Button, Input, Switch, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, makeStyles, shorthands } from "@fluentui/react-components";
import { SaveEditRegular, EditRegular } from "@fluentui/react-icons";
import { useState } from "react";

import { getValueType } from "../../Helpers/getValueType";

interface ModuleConfigTableProps<T extends { key: string, displayName: string, value: string | number | boolean | Date | undefined }[]> {
    data: T;
    appFeature: string;
    onUpdate: (key: string, value: string | number | boolean) => void;
}

const useStyles = makeStyles({
    fullWidthInput: {
        width: '100%',
        display: "flex",
        flexDirection: "column",
    },
});

const ModuleConfigTable = <T extends { key: string, displayName: string, value: string | number | boolean }[]>({ data, appFeature, onUpdate }: ModuleConfigTableProps<T>) => {
    const styles = useStyles();
    const [editableData, setEditableData] = useState<T>(data);
    // console.log('[ModuleConfigTable] input data:', data)
    const [isEditing, setIsEditing] = useState<Record<string, boolean>>(
        data.reduce((acc: Record<string, boolean>, item) => {
            acc[item.key] = false;
            return acc;
        }, {})
    );

    const handleEditClick = (field: keyof T) => {
        setIsEditing({ ...isEditing, [field]: true });
    };

    const handleSaveClick = (field: keyof T) => {
        const item = editableData.find(item => item.key === field);
        if (item) {
            console.log("Saving data for:", item.key, item.value);
            onUpdate(item.key, item.value);
            setIsEditing({ ...isEditing, [field]: false }); // Need to trap and handle errors
        } else {
            console.log("No item found for:", field);
        }
    };

    const handleChange = (field: string, value: string | boolean | number) => {
        setEditableData(prev => prev.map(item => item.key === field ? { ...item, value: value } : item) as T);
    };

    return (
        <>
            <Table noNativeElements>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Setting</TableHeaderCell>
                        <TableHeaderCell>Value</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {editableData.map((item) => (
                        // console.log('Building row for Item:', item),
                        <TableRow key={item.key}>
                            <TableCell><TableCellLayout truncate>{item.displayName}</TableCellLayout></TableCell>
                            {
                                //Check to see if the value is a string, number, boolean, or null
                                //Usinng the getValueType function for strings to also check for time and dateTime
                                getValueType(item.value) === 'string' ? (
                                    // console.log('Im a string:', item.value),
                                    <TableCell>
                                        <TableCellLayout truncate >
                                            {isEditing[item.key] ? (
                                                <Input
                                                    className={styles.fullWidthInput}
                                                    size="medium"
                                                    value={item.value ? item.value.toString() : ''}
                                                    onChange={(e) => handleChange(item.key, e.target.value)}
                                                />
                                            ) : (
                                                item.value
                                            )}
                                        </TableCellLayout>
                                    </TableCell>
                                ) :
                                    getValueType(item.value) === 'time' ? (
                                        // console.log('Im a time:', item.value),
                                        <TableCell>
                                            <TableCellLayout truncate >
                                                <Input
                                                    className={styles.fullWidthInput}
                                                    size="medium"
                                                    type="time"
                                                    value={item.value ? item.value.toString() : ''}
                                                    onChange={(e) => handleChange(item.key, e.target.value)}
                                                    disabled={!isEditing[item.key]}
                                                />
                                            </TableCellLayout>
                                        </TableCell>
                                    ) :
                                        getValueType(item.value) === 'dateTime' ? (
                                            // console.log('Im a dateTime:', item.value),
                                            <TableCell>
                                                <TableCellLayout truncate >
                                                    <Input
                                                        className={styles.fullWidthInput}
                                                        size="medium"
                                                        type="datetime-local"
                                                        value={item.value ? (item.value.toString().endsWith('Z') ? item.value.toString().slice(0, -1) : item.value.toString()) : ''}
                                                        onChange={(e) => handleChange(item.key, e.target.value)}
                                                        disabled={!isEditing[item.key]}
                                                    />
                                                </TableCellLayout>
                                            </TableCell>
                                        ) :
                                            getValueType(item.value) === 'date' ? (
                                                // console.log('Im a dateTime:', item.value),
                                                <TableCell>
                                                    <TableCellLayout truncate >
                                                        <Input
                                                            className={styles.fullWidthInput}
                                                            size="medium"
                                                            type="date"
                                                            value={item.value ? new Date(item.value.toString()).toLocaleDateString() : ''}
                                                            onChange={(e) => handleChange(item.key, e.target.value)}
                                                            disabled={!isEditing[item.key]}
                                                        />
                                                    </TableCellLayout>
                                                </TableCell>
                                            ) :
                                                typeof item.value === 'number' ? (
                                                    // console.log('Im a Number:', item.value),
                                                    <TableCell>
                                                        <TableCellLayout truncate>
                                                            {isEditing[item.key] ? (
                                                                <Input
                                                                    size="medium"
                                                                    type="number"
                                                                    value={item.value.toString() || ''}
                                                                    onChange={(e) => handleChange(item.key, parseFloat(e.target.value))}
                                                                />
                                                            ) : (
                                                                item.value
                                                            )}
                                                        </TableCellLayout>
                                                    </TableCell>
                                                ) :
                                                    typeof item.value === 'boolean' ? (
                                                        // console.log('Im a boolean:', item.value),
                                                        <TableCell>
                                                            <TableCellLayout truncate>
                                                                <Switch
                                                                    key={item.key}
                                                                    label={item.value ? 'True' : 'False'}
                                                                    labelPosition="before"
                                                                    checked={item.value}
                                                                    onChange={(e) => handleChange(item.key, e.target.checked)}
                                                                    disabled={!isEditing[item.key]}
                                                                />
                                                            </TableCellLayout>
                                                        </TableCell>
                                                    ) :
                                                        item.value === null ? (
                                                            // console.log('Im a null:', item.key),
                                                            <TableCell>
                                                                <TableCellLayout truncate>
                                                                    {isEditing[item.key] ? (
                                                                        <Input
                                                                            className={styles.fullWidthInput}
                                                                            size="medium"
                                                                            value={item.value || ''}
                                                                            onChange={(e) => handleChange(item.key, e.target.value)}
                                                                        />
                                                                    ) : (
                                                                        item.value
                                                                    )}
                                                                </TableCellLayout>
                                                            </TableCell>
                                                        )
                                                            : null
                            }
                            <TableCell>
                                {isEditing[item.key] ? (
                                    <Button icon={<SaveEditRegular />} onClick={() => handleSaveClick(item.key as keyof T)} />
                                ) : (
                                    <Button icon={<EditRegular />} onClick={() => handleEditClick(item.key as keyof T)} />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}

export default ModuleConfigTable;