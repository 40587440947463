import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useSignInState } from './Components/signInState';
import { appTheme } from './Helpers/customRevealTheme';
import { lightPowerONTheme, darkPowerONTheme, brandPowerON } from './Helpers/fluentPowerONTheme';

import LoadingPage from './Components/LoadingPage';
import useFetch from './Hooks/useFetchAPI';

import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';

declare let $: any;

const CeateDashboard = (props: any) => {
  ($.ig.RevealSdkSettings as any).setBaseUrl(process.env.REACT_APP_REVEAL_CUSTOM_URL);
  const { inProgress, accounts, instance } = useMsal();
  const { signInState } = useSignInState();
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dashboardSelectorCallback, setDashboardSelectorCallback] = useState<Function | null>(null);

  const { fetchAPI: fetchDashboards, apiData: dashboardData, isLoading: isLoadingDashboards, serverError: serverErrorDashboards } = useFetch({ scope: 'AppUser' })

  const fetchDashList = async () => {
    await fetchDashboards({ url: `${process.env.REACT_APP_REVEAL_DATA_URL}customdashboards`, method: 'GET' });
  };

  const checkAccessTokenExpiry = async (accessToken: string) => {
    // Split the token into parts
    const payload = accessToken.split('.')[1];
    // Base64 decode the payload
    const decodedPayload = atob(payload);
    // Parse the decoded payload to get an object
    const payloadObj = JSON.parse(decodedPayload);
    // Get the expiration time from the payload
    const exp = payloadObj.exp;
    return exp;
  }

  const revealHeaders = async () => {
    const silentResult = await instance.acquireTokenSilent({
      scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/AppUser`],
      account: accounts[0],
      forceRefresh: true
    });

    const expiryTime = await checkAccessTokenExpiry(silentResult.accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = expiryTime - currentTime;
    // Schedule the next refresh 4 minutes before expiry just as an example
    const refreshTimeout = (timeUntilExpiry - 240) * 1000;
    setTimeout(revealHeaders, refreshTimeout);

    ($.ig.RevealSdkSettings as any).setAdditionalHeadersProvider((url: string) => {
      const headers: { [key: string]: string | undefined } = {};
      headers['x-customertid'] = signInState.customerInfo.find((customer: { CustomerID: number; }) => customer.CustomerID === props.currentCustomerID)?.TenantID
      headers["Authorization"] = `Bearer ${silentResult.accessToken}`
      return headers
    });
    $.ig.RevealSdkSettings;
  };

  const openDialog = (callback: any) => {
    fetchDashList()
      .then(() => {
        setIsDialogOpen(true);
      });
  }

  useEffect(() => {
    $.ig.RevealSdkSettings.theme = appTheme(signInState.userInfo[0]?.ThemePreference);
    darkPowerONTheme.colorBrandForeground1 = brandPowerON[110]; // use brand[110] instead of brand[100]
    darkPowerONTheme.colorBrandForeground2 = brandPowerON[120]; // use brand[120] instead of brand[110]
  }, [signInState.userInfo[0]?.ThemePreference]);

  useEffect(() => {
    if (inProgress === 'none') {
      setIsLoading(true);
      revealHeaders()
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [inProgress, props.currentCustomerID]);

  useEffect(() => {
    if (!isLoading) {
      var revealView = new $.ig.RevealView('#revealView');
      revealView.startInEditMode = true;

      revealView.onDataSourcesRequested = async (callback: any) => {

        await revealHeaders();

        // Intune Full Database
        let intuneDataSource = new $.ig.RVAzureSqlDataSource();
        intuneDataSource.id = 'EntraIntune';
        intuneDataSource.title = 'Entra and Intune Data Source';
        intuneDataSource.subtitle = 'Entra and Intune Data';

        // Intune Summary Tables Only
        let intuneSummariesDataSource = new $.ig.RVAzureSqlDataSource();
        intuneSummariesDataSource.id = 'IntuneSummaries';
        intuneSummariesDataSource.title = 'Intune Summary Tables Data Source';
        intuneSummariesDataSource.subtitle = 'Intune Data';

        // Intune Summary Tables Only
        let commonDataSource = new $.ig.RVAzureSqlDataSource();
        commonDataSource.id = 'CommonData';
        commonDataSource.title = 'Common Data Source';
        commonDataSource.subtitle = 'Common Data';

        // Intune Curated Items
        let dataSourceItem1 = new $.ig.RVAzureSqlDataSourceItem(intuneDataSource);
        dataSourceItem1.id = 'DeviceInfo';
        dataSourceItem1.title = 'Common Device Information';
        dataSourceItem1.subtitle = 'Information about devices in Entra and Intune';

        let dataSourceItem2 = new $.ig.RVAzureSqlDataSourceItem(intuneDataSource);
        dataSourceItem2.id = 'WindowsReleaseInfo';
        dataSourceItem2.title = 'Windows Update Information';
        dataSourceItem2.subtitle = 'Information about Windows Update state for devices';

        callback(new $.ig.RevealDataSources([intuneDataSource, commonDataSource, intuneSummariesDataSource], [dataSourceItem1, dataSourceItem2], false));
      };

      revealView.onSave = (rv: any, args: any) => {
        console.log('RV Args:', args);
        if (args.saveAs) {
          const newName = prompt('Enter a new name for the dashboard:', args.name);
          console.log('Save As:', args.saveAs);
          args.dashboardId = args.name = newName;
        }
        else if (args.name === 'New Dashboard') {
          alert('You must name the dashboard other then "New Dashboard" before saving.');
          args.cancel = true;
        }
        // else if (args.name.contains(' ')) {
        //   //Need to find a better way to handle spaces in the name rather than stripping them out
        //   console.log('Name contains spaces:', args.name);
        //   args.dashboardId = args.name.replace(/\s/g, '');
        // }
        else {
          args.dashboardId = args.name;
        }
        //call fetchDashList and await its completion before checking dashboardData to see if it contains a dashboard with the same name
        fetchDashList()
          .then(() => {
            if (Array.isArray(dashboardData) && dashboardData.some((dashboard: any) => dashboard.name === args.dashboardId)) {
              alert('A dashboard with that name already exists. Please choose a different name.');
              args.cancel = true;
            }
            else {
              args.saveFinished();
            }
          });
      }

      revealView.onDashboardSelectorRequested = (args: any) => {
        setDashboardSelectorCallback(() => args.callback);
        openDialog(args.callback);
      }

      revealView.onLinkedDashboardProviderAsync = (dashboardId: string) => {
        return $.ig.RVDashboard.loadDashboard(dashboardId);
      };

    }
  }, [isLoading]);

  return (
    isLoading
      ? <LoadingPage message="Getting Datasources..." />
      : <>
        <Dialog modalType="alert"
          open={isDialogOpen}
          onOpenChange={(event, data) => {
            setIsDialogOpen(data.open);
          }}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle
                action={
                  <DialogTrigger action="close">
                    <Button
                      appearance="subtle"
                      aria-label="close"
                      icon={<Dismiss24Regular />}
                    />
                  </DialogTrigger>
                }
              >
                Choose a dashboard to link
              </DialogTitle>
              <DialogContent>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  {Array.isArray(dashboardData) ? dashboardData.map((item: { name: string }) => (
                    <div style={{ flex: '1 0 170px', maxWidth: '170px', margin: '10px' }}>
                      <Button key={item.name} onClick={() => {
                        setIsDialogOpen(false);
                        if (dashboardSelectorCallback) {
                          dashboardSelectorCallback(item.name);
                        }
                      }} style={{
                        maxWidth: '170px',
                        minWidth: '170px',
                        height: '3em',
                      }}>
                        {item.name}
                      </Button>
                    </div>
                  )) : null}
                </div>
              </DialogContent>
            </DialogBody>
          </DialogSurface>
        </Dialog>
        <div id='revealView' style={{ height: 'calc(100vh - 100px)', width: '100%' }}></div>
      </>
  );


};

export default CeateDashboard;