import React, { useState, useEffect, ChangeEvent } from 'react';
import { useMsal } from '@azure/msal-react';
import {
    Card,
    Text,
    Textarea,
    Label,
    Button,
    Select,
    Input,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Title3,
    Title2
} from "@fluentui/react-components";
import type { PositioningImperativeRef, PopoverProps } from "@fluentui/react-components";
import { MailAddRegular, PhoneAddRegular, ArrowPreviousRegular, PersonAccountsRegular, BuildingMultipleRegular } from "@fluentui/react-icons";

import LoadingPage from './LoadingPage';
import { useAuthHeader } from './getAuthHeader';
import { useSignInState } from './signInState';

interface InviteUserProps {
    CustomerID: number;
    onBack: () => void;
}

interface FormFields {
    ContactName: string,
    ContactAADID: string,
    CompanyName: string,
    Email: string;
    phoneNumber: string;
}

const InviteUser: React.FC<InviteUserProps> = ({ CustomerID, onBack }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { inProgress, accounts } = useMsal();
    const { signInState } = useSignInState();
    const [authHeader, setAuthHeader] = useState({});

    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const positioningRef = React.useRef<PositioningImperativeRef>(null);

    const [formData, setFormData] = useState<FormFields>({
        ContactName: accounts[0].name || accounts[0].username,
        ContactAADID: accounts[0].localAccountId,
        CompanyName: signInState.customerInfo.find(customer => customer.CustomerID == CustomerID)?.CompanyName || '',
        Email: '',
        phoneNumber: ''
    });

    const [showPopover, setShowPopover] = useState(false);
    const [popOverMessage, setpopOverMessage] = useState('');

    const getAuthHeader = useAuthHeader('AdminUser', 'POST');

    useEffect(() => {
        const fetchAuthHeader = async () => {
            const header = await getAuthHeader();
            setAuthHeader(header);
        };
        if (inProgress === "none" && accounts.length > 0) {
            fetchAuthHeader();
        }
    }, [inProgress, accounts]);

    React.useEffect(() => {
        if (buttonRef.current) {
            positioningRef.current?.setTarget(buttonRef.current);
        }
    }, [buttonRef, positioningRef]);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
        setShowPopover(data.open || false);

    const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    function isRequestInit(obj: any): obj is RequestInit {
        return obj && typeof obj === 'object' && 'method' in obj && 'headers' in obj
    }

    const handleSignUp = async (e: React.FormEvent) => {
        console.log('Form submitted:', JSON.stringify(formData));
        e.preventDefault();
        setShowPopover(false); // Hide the popover initially

        try {
            if (isRequestInit(authHeader)) {
                const response = await fetch(`${apiUrl}admin/invitenewuser`, {
                    ...authHeader,
                    body: JSON.stringify({ "UserID": signInState.userInfo[0].UserID, "CustomerID": CustomerID, "FormData": formData })
                });

                if (!response.ok) {
                    const data = await response.json();
                    console.error('Failed to invite user:', data.message, data.error);
                    setShowPopover(true);

                    if (data.error === 'There is already an invite for this user.') {
                        setpopOverMessage('An invite already exists and is still valid for this user');
                    }
                    // Handle other defined/situational errors as we think of them.
                    else if (data.error === 'Some other error message.') {
                        //setpopOverMessage('An invite already exists and is still valid for this user');
                    }
                    else {
                        setpopOverMessage('Whoops! Something went wrong. Please try again later.');
                    }
                    return;
                }

                console.log('Customer registered successfully');
                onBack(); // Call the onBack method when the request is successful
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setpopOverMessage('Whoops! Something went wrong. Please try again later.');
        }
    };

    return (
        <div style={{ margin: '20px' }}>
            <div className="onBack" style={{ marginBottom: '20px' }}>
                <Button icon={<ArrowPreviousRegular />} onClick={onBack} >Back to all invites</Button>
            </div>
            <div>
                <form onSubmit={handleSignUp}>
                    <div className="title" style={{textAlign: 'center', marginBottom: '10px'}}>
                    <Title2>Invite a new user</Title2>
                    </div>
                    <Label>Invite will be sent on behalf of:</Label>
                    <Input contentBefore={<PersonAccountsRegular />} disabled type="text" name="ContactName" value={formData.ContactName} style={{ width: '100%' }} />
                    <Label>For company:</Label>
                    <Input contentBefore={<BuildingMultipleRegular />} disabled type="text" name="CompanyName" value={formData.CompanyName} style={{ width: '100%' }} />
                    <Label required>User's email</Label>
                    <Input contentBefore={<MailAddRegular />} type="email" name="Email" value={formData.Email} onChange={handleChange} placeholder="Email" required style={{ width: '100%' }} />
                    <Label required>User's contact telephone number</Label>
                    <Input contentBefore={<PhoneAddRegular />} type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required style={{ width: '100%' }} />
                    <Popover withArrow open={showPopover} positioning={{ positioningRef }} mouseLeaveDelay={1} closeOnIframeFocus closeOnScroll onOpenChange={handleOpenChange}>
                        <PopoverTrigger disableButtonEnhancement>
                            <Button ref={buttonRef} style={{ margin: '10px auto 10px', display: 'block' }} type="submit">Invite user</Button>
                        </PopoverTrigger>
                        <PopoverSurface tabIndex={-1}>
                            <div style={{ padding: '10px' }}> {popOverMessage}</div>
                        </PopoverSurface>
                    </Popover>
                </form>
            </div>
        </div>
    );
}

export default InviteUser;