function isValidDate(value: string): boolean {
    const parts = value.split("/");
    if (parts.length !== 3) {
        return false;
    }
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // months are 0-based in JavaScript
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    if (date && date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
        return true;
    }
    // Try the other format
    const date2 = new Date(year, day - 1, month + 1);
    return date2 && date2.getFullYear() === year && date2.getMonth() === day - 1 && date2.getDate() === month + 1;
}

export const getValueType = (value: any) => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])[\/](0[1-9]|1[012])[\/](19|20)\d\d$|^((0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](19|20)\d\d)$/;
    switch (typeof value) {
        case 'number':
            return 'number';
        case 'boolean':
            return 'boolean';
        case 'string':
            if (dateRegex.test(value)) {
                return 'date';
                // if (isValidDate(value)) {
                //     return 'date';
                // }
            } else if (/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/.test(value)) {
                return 'time';
            }
            return 'string';
        case 'object':
            if (value instanceof Date) {
                return 'dateTime';
            }
            // Add more cases as we find other specific object types
            // that map to specific SQL types
            return 'string';
        default:
            return 'string';
    }
};