import React, { useState, useEffect } from 'react';
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    TableCellLayout,
    Button,
} from "@fluentui/react-components";
import {
    EditRegular,
    DeleteRegular,
} from "@fluentui/react-icons";
import { useMsal } from '@azure/msal-react';

import LoadingPage from './LoadingPage';
import { useAuthHeader } from './getAuthHeader';

interface UserTableProps {
    onSelectUser: (user: UserType) => void;
    CustomerID: number | undefined;
}

const UserTable: React.FC<UserTableProps> = ({ onSelectUser, CustomerID }) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [users, setUsers] = useState<UserType[]>([]);
    const [hasUsers, setHasUsers] = useState(false);
    const [isPending, setIsPending] = useState(true);
    const [authHeader, setAuthHeader] = useState({});
    const { inProgress, accounts } = useMsal();

    const getAuthHeader = useAuthHeader('AdminUser', 'POST');

    useEffect(() => {
        const fetchAuthHeader = async () => {
            const header = await getAuthHeader();
            setAuthHeader(header);
        };
        if (inProgress === "none" && accounts.length > 0) {
            fetchAuthHeader();
        }
    }, [inProgress, accounts]);

    useEffect(() => {
        // Fetch users based on the CustomerID from the /getusers API
        function isRequestInit(obj: any): obj is RequestInit {
            return obj && typeof obj === 'object' && 'method' in obj && 'headers' in obj
        }
        if (isRequestInit(authHeader)) {
            fetch(`${apiUrl}admin/getallusers`, {
                ...authHeader,
                body: JSON.stringify({ "CustomerID": CustomerID })
            })
                .then(response => response.json())
                .then(data => {

                    if (data.length === 0) {
                        console.error('No users found for the CustomerID:', CustomerID);
                        setHasUsers(false);
                        setIsPending(false);
                    } else {
                        setHasUsers(true);
                        setUsers(data);
                        setIsPending(false);
                    }

                })
                .catch(error => console.error(error));

        }
    }, [authHeader, CustomerID]);

    if (isPending) {
        return (
            < LoadingPage message="Loading users..." />
        );
    }
    else {
        if (hasUsers) {
            return (
                <div style={{ margin: '20px' }}>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>User</TableHeaderCell>
                                <TableHeaderCell>Email</TableHeaderCell>
                                <TableHeaderCell>State</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.UserID}>
                                    {(["DisplayName", "Email", "IsActive"] as (keyof UserType)[]).map((key) => (
                                        <TableCell key={key as string}>
                                            <TableCellLayout truncate>
                                                {key === "IsActive" ? (user[key] ? "Active" : "Disabled") :
                                                    key === "DisplayName" ? (user.DisplayName ? user.DisplayName : user.UserName) : user[key]}
                                            </TableCellLayout>
                                        </TableCell>
                                    ))}
                                    <TableCell role="gridcell" tabIndex={0} >
                                        <TableCellLayout>
                                            <Button icon={<EditRegular />} aria-label="Edit" onClick={() => onSelectUser(user)} />
                                            <Button icon={<DeleteRegular />} aria-label="Delete" />
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            );
        }
        if (!hasUsers) {
            console.log("Has users (No Table):", hasUsers);
            return (
                <div>
                    <h3>No users found for the CustomerID: {CustomerID}</h3>
                </div>
            );
        }
    };
    return null;
};

export default UserTable;