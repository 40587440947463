import { Avatar } from "@fluentui/react-components";

function useAvatar(imageURL: string | undefined, userName: string | undefined) {
  return (
    <Avatar
      name={userName}
      image={{
        src: imageURL,
      }}
    />
  );
}

export default useAvatar;
