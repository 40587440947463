import React, { useEffect, useState } from 'react';
import {
    Tab,
    TabList,
    TabValue,
} from "@fluentui/react-components";
import { useMsal } from '@azure/msal-react';

import { appTheme } from './Helpers/customRevealTheme';
import { lightPowerONTheme, darkPowerONTheme, brandPowerON } from './Helpers/fluentPowerONTheme';

import { useRevealAdditionalHeader } from './Components/getRevealAdditionalHeader';
import { useSignInState } from './Components/signInState';
import LoadingPage from './Components/LoadingPage';
import useFetch from './Hooks/useFetchAPI';

declare let $: any;

const ViewDashboard = (props: any) => {
    const dashboardURL = `${process.env.REACT_APP_REVEAL_DATA_URL}dashboards`;
    ($.ig.RevealSdkSettings as any).setBaseUrl(process.env.REACT_APP_REVEAL_BASE_URL);

    const { inProgress, accounts, instance } = useMsal();
    const { signInState } = useSignInState();
    const [dashboardId, setDashboardId] = useState('Home');
    const [dashboards, setDashboards] = useState<DashboardDataResult[]>([]);
    const [isLoading, setIsLoading] = useState(true)

    const { fetchAPI: fetchDashboards, apiData: dashboardData, isLoading: isLoadingDashboards, serverError: serverErrorDashboards } = useFetch({ scope: 'AppUser' })

    useEffect(() => {
        $.ig.RevealSdkSettings.theme = appTheme(signInState.userInfo[0]?.ThemePreference);
        darkPowerONTheme.colorBrandForeground1 = brandPowerON[110]; // use brand[110] instead of brand[100]
        darkPowerONTheme.colorBrandForeground2 = brandPowerON[120]; // use brand[120] instead of brand[110]
    }, [signInState.userInfo[0]?.ThemePreference]);

    const changeDashboard = (dashboardName: string) => (e: any) => {
        setDashboardId(dashboardName || 'Home');
    };

    useEffect(() => {
        const revealHeaders = async () => {
            const silentResult = await instance.acquireTokenSilent({
                scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/AppUser`],
                account: accounts[0],
            });

            ($.ig.RevealSdkSettings as any).setAdditionalHeadersProvider((url: string) => {
                const headers: { [key: string]: string | undefined } = {};
                headers['x-customertid'] = signInState.customerInfo.find((customer: { CustomerID: number; }) => customer.CustomerID === props.currentCustomerID)?.TenantID
                headers["Authorization"] = `Bearer ${silentResult.accessToken}`
                return headers
            });
            $.ig.RevealSdkSettings;
        };
        if (inProgress === 'none') {
            setIsLoading(true);
            revealHeaders()
                .then(() => {
                    setIsLoading(false);
                });
        }
    }, [inProgress, props.currentCustomerID]);

    useEffect(() => {
        setDashboards([])
        const fetchDashList = async () => {
            await fetchDashboards({ url: dashboardURL, method: 'GET' });
        };
        fetchDashList()
            .catch((error) => {
                console.error('Error fetching dashboards', error);
                setDashboards([]);
            });
    }, [props.currentCustomerID]);

    useEffect(() => {
        if (!isLoading) {
            $.ig.RVDashboard.loadDashboard(dashboardId).then((dashboard: any) => {
                var revealView = new $.ig.RevealView('#revealView');
                revealView.dashboard = dashboard;
                revealView.canEdit = false;
                revealView.canSaveAs = false;

                revealView.onLinkedDashboardProviderAsync = (dashboardId: string) => {
                    return $.ig.RVDashboard.loadDashboard(dashboardId);
                  };

            });
        }
    }, [dashboardId, isLoading, props.currentCustomerID]);

    useEffect(() => {
        console.log('[IntuneDashboards] dashboards:', dashboardData);
        setDashboards(dashboardData as unknown as DashboardDataResult[]);
    }, [dashboardData]);


    return (
        <div className='Dashboard' style={{ padding: '10px' }}>
            {isLoadingDashboards && <LoadingPage message="Loading Dashboards..." />}
            {!isLoadingDashboards && dashboards &&
                <>
                    {/* <TabList selectedValue={dashboardId}>
                        {dashboards.map((dashboard: any, index: number) => {
                            return (
                                <Tab tabIndex={index} key={index} value={dashboard.name} onClick={changeDashboard(dashboard.name)}>
                                    {dashboard.name}
                                </Tab>
                            );
                        })}
                    </TabList> */}
                     <TabList selectedValue={dashboardId}>
                        <Tab tabIndex={1} key={1} value={'Home'} onClick={changeDashboard('Home')}>
                            Home
                        </Tab>
                        <Tab tabIndex={2} key={2} value={'Windows Update Insights'} onClick={changeDashboard('Windows Update Insights')}>
                            Windows Update Insights
                        </Tab>
                        <Tab tabIndex={3} key={3} value={'Feature Update Insights'} onClick={changeDashboard('Feature Update Insights')}>
                        Feature Update Insights
                        </Tab>
                        <Tab tabIndex={4} key={4} value={'Application Management'} onClick={changeDashboard('Application Management')}>
                        Application Management
                        </Tab>
                        <Tab tabIndex={5} key={5} value={'Asset Insights'} onClick={changeDashboard('Asset Insights')}>
                        Asset Insights
                        </Tab>
                        <Tab tabIndex={6} key={6} value={'Environment Compliance'} onClick={changeDashboard('Environment Compliance')}>
                        Environment Compliance
                        </Tab>
                        <Tab tabIndex={7} key={7} value={'Mobile Device Insights'} onClick={changeDashboard('Mobile Device Insights')}>
                        Mobile Device Insights
                        </Tab>
                        <Tab tabIndex={8} key={8} value={'Audit Insights'} onClick={changeDashboard('Audit Insights')}>
                        Audit Insights
                        </Tab>
                    </TabList>
                    <div id='revealView' style={{ height: 'calc(100vh - 130px)', width: '100%' }}></div>
                </>
            }
        </div>
    );
};

export default ViewDashboard;