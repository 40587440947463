import {
    Card,
    Text,
    Persona,
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    Button,
    Switch,
    Subtitle1,
    Title3,
    Input,
    makeStyles,
} from "@fluentui/react-components";
import { EditRegular, SaveEditRegular, ArrowPreviousRegular } from "@fluentui/react-icons";

import { useEffect, useState } from 'react';


import useFetch from '../Hooks/useFetchAPI';
import { useSignInState } from './signInState';
import { UserInformationSkeleton } from './LoadingSkeletons';
import useUpdateUserDetails from './updateUserDetails';

interface EditUserProps {
    user: UserType;
    customerId: number; // | undefined;
    onBack: () => void;
}

const useStyles = makeStyles({
    fullWidthInput: {
        width: '100%',
    },
});

const EditUser: React.FC<EditUserProps> = ({ user, customerId, onBack }) => {
    const styles = useStyles();
    const { signInState, setSignInState } = useSignInState();

    const [selectedUser, setSelectedUser] = useState<UserInfoType | null>(null);

    const { updateUserDetails } = useUpdateUserDetails(); //To replace with useFetchAPI

    const { fetchAPI: setIsActive, apiData: isActiveData, isLoading: isLoading, serverError: serverError } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: updateUserFeature, apiData: userFeatureData, isLoading: isLoadingUserFeature, serverError: serverErrorUserFeature } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: getUserDetails, apiData: userDetailsData, isLoading: isLoadingUserDetails, serverError: serverErrorUserDetails } = useFetch({ scope: 'AdminUser' })
    
    const updateUserIsActive = async (userId: number, state: boolean) => {
        await setIsActive({ url: `admin/setuserstatus?userId=${userId}&state=${state}`, method: 'PUT' });
    };

    useEffect(() => {
        const getUserDetailsAsync = async (userId: number) => {
            await getUserDetails({ url: `admin/getuserdetails?userId=${userId}`, method: 'GET' });
        };
        console.log('fetching user details for user:', user.UserID, 'customerId:', customerId)
        getUserDetailsAsync(user.UserID)
    }, [user, customerId]);

    useEffect(() => {
        if (!isLoadingUserDetails && !serverErrorUserDetails && userDetailsData) {
            console.log('User details data:', userDetailsData);
            let userDetailsDataResults: UserInfoType | null = null;
            if (userDetailsData) {
                userDetailsDataResults = (userDetailsData as unknown as UserInfoType);
                setSelectedUser(userDetailsData as unknown as UserInfoType);
                console.log('User details:', userDetailsDataResults);
            }
        }
    }, [isLoadingUserDetails, serverErrorUserDetails, userDetailsData]);

useEffect(() => {
    console.log('[EditUser] selectedUser:', selectedUser);
}, [selectedUser]);

    const [editableData, setEditableData] = useState<Partial<UserType>>({
        FirstName: user?.FirstName,
        LastName: user?.LastName,
        DisplayName: user?.DisplayName,
        PhoneNumber: user?.PhoneNumber,
    });

    const [isEditing, setIsEditing] = useState<{ [K in keyof Partial<UserType>]: boolean }>({
        DisplayName: false,
        FirstName: false,
        LastName: false,
        PhoneNumber: false,
    });

    // Handles showing the text area for editing
    const handleEditClick = (field: keyof UserType) => {
        setIsEditing({ ...isEditing, [field]: true });
    };

    // Handles saving the edited data
    const handleSaveClick = (field: keyof UserType) => {
        console.log("Saving data for field:", field, editableData[field]);
        updateUserDetails(field, editableData[field], user.UserID);
        setIsEditing({ ...isEditing, [field]: false });// Need to trap and handle errors
    };

    const updateUserFeatures = async (selectedUserId: number, featureId: number, state: boolean) => {
        await updateUserFeature({ url: `admin/updateuserfeatures?userId=${selectedUserId}&featureId=${featureId}&state=${state}`, method: 'PUT' });
        if (!serverErrorUserFeature) {
            console.log('User features updated successfully');
            if (signInState.userInfo[0].UserID === selectedUserId) {
                // Update the previous signInState with the new state of the featureId (FeatureID in userFeatures)
                setSignInState({ ...signInState, userFeatures: signInState.userFeatures.map((userFeature) => {
                            if (userFeature.FeatureID === featureId) {
                                return { ...userFeature, Enabled: state };
                            }
                            return userFeature;
                        })
                    });
                }
            }
        };

    const handleChange = (field: keyof UserType, value: string) => {
        setEditableData(prev => ({ ...prev, [field]: value }));
    };

    return (
        <div style={{ margin: '20px' }}>
            <div className="onBack" style={{ marginBottom: '20px' }}>
                <Button icon={<ArrowPreviousRegular />} onClick={onBack} >Back to all users</Button>
            </div>
            {isLoadingUserDetails && !serverErrorUserDetails && !selectedUser && <Card><UserInformationSkeleton /></Card>}

            {!isLoadingUserDetails && !serverErrorUserDetails && selectedUser && 
            <Card>
                <Title3>User Information</Title3>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Display the Persona card for the user */}
                    <Persona
                        key={user.UserID}
                        name={user.DisplayName || ((user.FirstName || '') + ' ' + (user.LastName || ''))}
                        secondaryText={user.Email}
                        size={"huge"}
                    />

                    {/* Display the Switch to enable or disable a user */}

                    <Switch style={{ marginLeft: '10px' }}
                        key="IsActive"
                        label="User Enabled"
                        checked={user.UserID === signInState.userInfo[0].UserID ? user.IsActive : undefined}
                        defaultChecked={user.UserID !== signInState.userInfo[0].UserID ? user.IsActive : undefined}
                        labelPosition="before"
                        title={user.UserID === signInState.userInfo[0].UserID ? "You cannot change your own account's active status" : ""}
                        onChange={(event, checked) => {
                            if (user.UserID !== signInState.userInfo[0].UserID) {
                                updateUserIsActive(user.UserID, checked.checked);
                            }
                        }}
                    />

                </div>

                {/* Render the dynamic table with the user core details (Display name, First Name, etc)  */}
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>User Info</TableHeaderCell>
                            <TableHeaderCell>Value</TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {(["DisplayName", "FirstName", "LastName", "PhoneNumber"] as (keyof UserType)[]).map((key) => (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>
                                    {isEditing[key] ? (
                                        <Input
                                            className={styles.fullWidthInput}
                                            size="medium"
                                            value={editableData[key]?.toString() ?? ''}
                                            onChange={(e) => handleChange(key, e.target.value)}
                                        />
                                    ) : (
                                        editableData[key]
                                    )}
                                </TableCell>
                                <TableCell>
                                    {isEditing[key] ? (
                                        <Button icon={<SaveEditRegular />} onClick={() => handleSaveClick(key)} />
                                    ) : (
                                        <Button icon={<EditRegular />} onClick={() => handleEditClick(key)} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {
                    <div key={customerId}>
                        {/* <Text weight="semibold" underline>Features for custoner {customerId}</Text> */}
                        <br></br>
                        <div>
                            <Subtitle1>Features</Subtitle1>
                            <div>
                                {
                                    selectedUser && selectedUser.availableFeatures.length > 0 ? (
                                        selectedUser.availableFeatures.map((feature) => {
                                            // Find the userFeature with the same FeatureID
                                            const userFeature = selectedUser.userFeatures.find(
                                                (userFeature) => userFeature.FeatureID === feature.FeatureID
                                            );

                                            return (
                                                <Switch
                                                    key={feature.FeatureID}
                                                    label={feature.FeatureName}
                                                    defaultChecked={userFeature ? userFeature.Enabled : false}
                                                    labelPosition="before"
                                                    onChange={(event, checked) => {
                                                        updateUserFeatures(selectedUser?.userInfo[0].UserID, feature.FeatureID, checked.checked);
                                                    }}
                                                />
                                            );
                                        })
                                    ) : (
                                        <Text>No available features</Text>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <Subtitle1>Role</Subtitle1>
                            <div>
                                {
                                    selectedUser && selectedUser.userRoles.length > 0 ? (
                                        selectedUser.userRoles.map((role) => (
                                            <Text key={role.RoleID}>{role.RoleName}</Text>
                                        ))
                                    ) : (
                                        <Text>No assigned roles</Text>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }

            </Card>
}
        </div>

    );
};


export default EditUser;
