import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Text, Image, Button, makeStyles, shorthands } from '@fluentui/react-components';
import { PersonAccountsRegular, SignOutRegular } from '@fluentui/react-icons';

import LoadingPage from './Components/LoadingPage';
import { useSignInState } from './Components/signInState';
import { loginRequest } from './Components/authConfig';
import authErrorHandling from './Components/authErrorHandling';
import { useAuthHeader } from './Components/getAuthHeader';
import { useHandleLogout } from './Components/HandleLogout';
import SignUpFormNewUser from './Components/RegisterUser';


const useStyles = makeStyles({
    containerLight: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...shorthands.gap('1rem'),
        ...shorthands.padding('2rem'),
        backgroundImage: `url(${require('./Assets/acceptinvite.png')})`,
        backgroundSize: 'cover',
        boxSizing: 'border-box',
    },
    containerDark: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...shorthands.gap('1rem'),
        ...shorthands.padding('2rem'),
        // backgroundImage: `url(${require('./Assets/acceptinvite.png')})`,
        backgroundSize: 'cover',
        boxSizing: 'border-box',
    },
    heroImage: {
        width: '100%',
        height: 'auto',
    },
    welcomeText: {
        fontSize: '2rem',
        textAlign: 'center',
    },
    instructionText: {
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const AcceptInvite = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const styles = useStyles();
    const location = useLocation();
    const { instance, accounts, inProgress } = useMsal();

    const { handleLogout } = useHandleLogout();
    const [inviteId, setInviteId] = useState<string | null>('');
    const { signInState } = useSignInState();
    const getAuthHeader = useAuthHeader('AppUser', 'POST');
    const [authError, setauthError] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [currentTheme, setCurrentTheme] = useState('lightmodecontainer');
    
    useEffect(() => {
        setCurrentTheme(signInState?.userInfo[0]?.ThemePreference === 'dark' ? 'Dark' : 'Light');
      }, [signInState]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const invite = query.get('invite');
        setInviteId(invite);
        if (inviteId === null) {
            setErrorMessage("Sorry, please click a link to redeem an invite");
            return;
        }
    }, [location]);

    const onLogin = async () => {
        try {
            const loginResponse = await instance.loginPopup(loginRequest)
            console.log('[HandleLogin] Login response:', loginResponse);

        } catch (error) {
            setauthError(authErrorHandling(error));
            console.log('[HandleLogin] Login error:', error);
        }
    }

    return (
        <div className={styles['container'+currentTheme as keyof typeof styles]}>
            {/* <Image src={require('./Assets/acceptinvite.png')} alt="Welcome" className={styles.heroImage} /> */}
            <Text className={styles.welcomeText}>Welcome to Additionality!</Text>

            {!inviteId ? <Text className={styles.instructionText}>Please use the link found in your invite e-mail to finish accepting an invite.</Text> :
                <>
                    <p>Invite ID: {inviteId}</p>
                    {accounts.length < 1 && <Text className={styles.instructionText}>Please log in to begin the registration process and accept your invite.</Text>}
                    <div className={styles.buttons}>
                        {accounts.length < 1 ?
                            <Button appearance='primary' size="medium" icon={<PersonAccountsRegular />} onClick={onLogin}>
                                Log in
                            </Button>
                            :
                            <Button size="medium" icon={<SignOutRegular />} onClick={handleLogout} >
                                Logout
                            </Button>
                        }
                    </div>
                    {inProgress === 'none' && accounts.length > 0 ?
                        <SignUpFormNewUser Token={inviteId} />
                        : null}
                </>
            }
            {inProgress === 'login' && <LoadingPage message="Logging in..." />}
        </div>
    );
}

export default AcceptInvite;