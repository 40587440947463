import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { makeStyles, Label, Button, Select, useId } from "@fluentui/react-components";
import { PersonAccountsRegular, SignOutRegular } from "@fluentui/react-icons";

import logo from '../Assets/Logos/LogoPink64.png';
import useAvatar from "../Components/Avatar";
import { useGetProfilePic } from '../Components/getProfilePic';
import { useHandleLogout } from '../Components/HandleLogout';
import { useSignInState } from '../Components/signInState';


type User = {
    name: string | undefined;
};

const useStyles = makeStyles({
    image: {
        columnGap: "15px",
        display: "inline-flex",
        justifyContent: "flex-start"
    },
    buttons: {
        columnGap: "15px",
        display: "inline-flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    },
    tenantSelector: {
        display: "inline-flex",
        alignItems: "center"
    }
});



export const Header = () => {
    const styles = useStyles();
    const selectId = useId();
    const { inProgress, accounts } = useMsal();
    const username: User = {
        name: accounts.length > 0 ? accounts[0].name : "",
    };

    const { signInState, setSignInState } = useSignInState();

    const { handleLogout } = useHandleLogout();
    const { getProfilePic } = useGetProfilePic();

    const [profilePic, setProfilePic] = useState<string | undefined>(undefined);

    useEffect(() => {
        const fetchProfilePic = async () => {
            const pic = await getProfilePic();
            setProfilePic(pic);
        };

        if (inProgress === "none" && accounts.length > 0) {
            fetchProfilePic();
        }
    }, [inProgress, accounts]);

    const avatar = useAvatar(profilePic, username.name);

    const onLogin = () => {
        // Add your login logic here
    };

    const handleTenantChange = (event: any) => {
        const selectedCompanyID: number = Number(event.target.value);
        setSignInState(prevState => ({ ...prevState, currentCustomerID: selectedCompanyID }));
    };

    return (
        <header>
            <div className={styles.container} style={{ paddingRight: '10px' }}>
                <img src={logo} alt="Logo" style={{ marginLeft: '10px', marginTop: '5px', marginBottom: '5px', width: '44px', height: '44px' }} />
                {signInState.customerInfo.length > 1 && (
                    <div className={styles.tenantSelector}>
                        <Select id={selectId} {...Select} onChange={handleTenantChange}>
                            {
                                signInState.customerInfo.map((info, index) => (
                                    <option key={index} value={info.CustomerID}>
                                        {info.CompanyName}
                                    </option>
                                ))
                            }
                        </Select>
                    </div>
                )}
                <div className={styles.buttons}>
                    {username ? (
                        <>
                            <Link to="/profile">
                                {avatar}
                            </Link>
                            {signInState.userInfo[0].DisplayName || username.name}
                            <Button size="medium" icon={<SignOutRegular />} onClick={handleLogout} >
                                Logout
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button size="medium" icon={<PersonAccountsRegular />} onClick={onLogin}>
                                Log in
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};