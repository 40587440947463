import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuthHeader } from './getAuthHeader';

const useUpdateUserProfile = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const { inProgress, accounts } = useMsal();
    const [authHeader, setAuthHeader] = useState({});
    const getAuthHeader = useAuthHeader('AppUser', 'PUT');

    useEffect(() => {
        const fetchAuthHeader = async () => {
            const header = await getAuthHeader();
            setAuthHeader(header);
        };
        if (inProgress === "none" && accounts.length > 0) {
            fetchAuthHeader();
        }
    }, [inProgress, accounts]);

    const updateUserProfile = useCallback(async (field: string, value: string | number | boolean | Date | null | undefined, userID: number) => {
        console.log('[updateUserDetails] About to update user details:', field, value, userID);
        function isRequestInit(obj: any): obj is RequestInit {
            return obj && typeof obj === 'object' && 'method' in obj && 'headers' in obj;
        }
        if (isRequestInit(authHeader)) {
            console.log('[updateUserDetails] Updating user details now...')
            const response = await fetch(`${apiUrl}users/updateuser`, {
                ...authHeader,
                body: JSON.stringify({ [field]: value, UserID: userID})
            }).then(response => response.json())

            if (response.ok) {
                setIsSuccess(true);
            } else {
                setIsError(true);
            }
        }
    }, [authHeader]);

    return { isSuccess, isError, updateUserProfile };
};

export default useUpdateUserProfile;