import React, { createContext, useState, useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

interface Module {
  ModuleId: number;
  name: string;
  DisplayName: string;
}

interface License {
  LicenseID: number;
  CustomerID: number;
  StartDate: string;
  ExpiryDate: string;
  Type: string;
  IsActive: boolean;
  Name: string;
  DisplayName: string;
  Description: string;
  modules: Module[];
}

interface SignInState {
  customerInfo: { CustomerID: number, TenantID: string, CompanyName: string, TenantInt: number, IsActive: boolean }[];
  licenseInfo: License[];
  userInfo: { PrimaryCustomerID: number, IsActive: boolean, UserName: string, Email: string, FirstName: string, LastName: string, DisplayName: string, PhoneNumber: string, UserID: number, ThemePreference: string }[];
  userFeatures: { CustomerID: number, FeatureID: number, FeatureName: string, Enabled: boolean }[];
  userRoles: { CustomerID: number, RoleID: number, RoleName: string }[];
  isAuthed: boolean;
  hasAccount: boolean;
  currentCustomerID: number;
  isLoading: boolean;
}

interface SignInStateContextType {
  signInState: SignInState;
  setSignInState: React.Dispatch<React.SetStateAction<SignInState>>;
  // navDrawerState: NavDrawerState;
  // setNavDrawerState: React.Dispatch<React.SetStateAction<NavDrawerState>>;
}

interface NavDrawerState {
  isOpen: boolean;
}

// Create a context
const SignInStateContext = createContext<SignInStateContextType | null>(null);

// Create a provider component
export const SignInStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [signInState, setSignInState] = useState<SignInState>({
    customerInfo: [],
    licenseInfo: [],
    userInfo: [],
    userFeatures: [],
    userRoles: [],
    isAuthed: false,
    hasAccount: false,
    currentCustomerID: 0,
    isLoading: true,
  });

  const [ navDrawerState, setNavDrawerState ] = useState<NavDrawerState>({ isOpen: false });

  const { instance, accounts } = useMsal();
  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      const msalInstance = new PublicClientApplication(msalConfig);

      await msalInstance.initialize();

      setIsMsalInitialized(true);
    };

    initializeMsal();
  }, []);

  useEffect(() => {
    const checkSignInState = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      const silentResult = await instance.acquireTokenSilent({
        scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/AppUser`],
        account: accounts[0],
      });

      const response = await fetch(`${apiUrl}initial/signin`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${silentResult.accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSignInState({ ...data, isLoading: false});
      }
      else {
        setSignInState({ customerInfo: [], licenseInfo: [], userInfo: [], userFeatures: [], userRoles: [], isAuthed: false, hasAccount: false, currentCustomerID: 0, isLoading: false});
      }
    }
    if (isMsalInitialized && accounts.length > 0) {
      checkSignInState()
    }
  }, [instance, accounts, isMsalInitialized]);

  return (
    // <SignInStateContext.Provider value={{ signInState, setSignInState, navDrawerState, setNavDrawerState }}></SignInStateContext.Provider>
    <SignInStateContext.Provider value={{ signInState, setSignInState }}>
      {children}
    </SignInStateContext.Provider>
  );
};

// Create a custom hook for using the signInState context
export const useSignInState = (): SignInStateContextType => {
  const context = useContext(SignInStateContext);
  if (!context) {
    console.log('useSignInState must be used within a SignInStateProvider');
    throw new Error('useSignInState must be used within a SignInStateProvider');
  }
  return context;
}