import { useEffect, useState } from 'react';
import { Card, Title3 } from '@fluentui/react-components';

import useFetch from '../Hooks/useFetchAPI';
import { useSignInState } from './signInState';
import ModuleConfigTable from './ModuleConfigs/ModuleConfigTable';

const getCompanyDetails = (props: any) => {
    const { signInState } = useSignInState();
    const { fetchAPI: fetchCompanyDetails, apiData: companyData, isLoading: isLoadingCompanyData, serverError: serverErrorCompanyData } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: updateDatabase } = useFetch({ scope: 'AdminUser' })
    const [companyInfo, setCompanyInfo] = useState<CompanyDataResult[] | null>(null);
    const [editableCompanyInfo, setEditableCompanyInfo] = useState<ModuleConfigTableProps[]>([]);

    useEffect(() => {
        const fetchCompanyDetailsAsync = async () => {
            await fetchCompanyDetails({ url: 'admin/getcompanydetails', method: 'GET' });
        };

        fetchCompanyDetailsAsync();
    }, [props.CustomerID, signInState.currentCustomerID]);

    useEffect(() => {
        if (!isLoadingCompanyData && !serverErrorCompanyData && companyData) {
            let companyDataResults: CompanyDataResult[] | null = null;
            if (companyData && (companyData as unknown as { result: CompanyDataResult[] }).result) {
                companyDataResults = (companyData as unknown as { result: CompanyDataResult[] }).result;
                setCompanyInfo(companyDataResults)

 
                let editableCompanyInfoResult: ModuleConfigTableProps[] = [];
                editableCompanyInfoResult = companyDataResults.flatMap(item => ([
                    { key: 'CompanyName', displayName: 'Company Display Name', value: item.CompanyName || '' },
                    { key: 'Address', displayName: 'Address', value: item.Address || '' },
                    { key: 'Country', displayName: 'Country', value: item.Country || '' },
                    { key: 'ContactName', displayName: 'Primary Contact Name', value: item.ContactName || '' },
                    { key: 'Email', displayName: 'Primary Contact Email', value: item.Email || '' },
                    { key: 'PhoneNumber', displayName: 'Primary Contact Phone Number', value: item.PhoneNumber || '' },
                ]));

                setEditableCompanyInfo(editableCompanyInfoResult);

            }
        }
    }, [isLoadingCompanyData, serverErrorCompanyData, companyData]);

    useEffect(() => {
        console.log('companyInfo:', companyInfo);

    }, [companyInfo]);


    const updateCompanyDetails = async (key: string, value: string | number | boolean) => {
        console.log('[CompanyDetails]: Updating CompanyDetails:', key, value);
        await updateDatabase({ url: `admin/updatecustomerdetails?key=${key}&value=${value}`, method: 'PUT' });

        setCompanyInfo((prev) => {
            if (prev && prev.length > 0) {
                return [
                    { ...prev[0], [key]: value },
                    ...prev.slice(1)
                ];
            }
            return prev;
        });
    }

    return (
        <div style={{ marginLeft: '20px' }}>
            <Title3>Company Details</Title3>
            
            {isLoadingCompanyData && <div>Loading Company Details...</div>}
            {companyInfo && editableCompanyInfo &&
                <>
                {/* Display some basic company details that are not editable */}
                    <p />
                    <Card className='CompanyInfo' style={{ maxWidth: '550px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', textAlign: 'left' }}>Microsoft Tenant ID:</div>
                                <div style={{ width: '50%', textAlign: 'left' }}>{companyInfo[0]?.TenantID}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', textAlign: 'left' }}>Microsoft Tenant Name:</div>
                                <div style={{ width: '50%', textAlign: 'left' }}>{companyInfo[0]?.TenantName}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', textAlign: 'left' }}>Microsoft Tenant Type:</div>
                                <div style={{ width: '50%', textAlign: 'left' }}>{companyInfo[0]?.TenantType}</div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%', textAlign: 'left' }}>Creation Date:</div>
                                <div style={{ width: '50%', textAlign: 'left' }}>{new Date(companyInfo[0]?.CreationDate).toLocaleDateString()}</div>
                            </div>
                        </div>
                    </Card>

{/* Display some further company details in a table for editing */}
                    <p />
                    <div className="EditableCompanyInfo" style={{ maxWidth: '1300px' }}>
                    < ModuleConfigTable<ModuleConfigTableProps[]> data={editableCompanyInfo} appFeature={'NACompanyDetails'} onUpdate={updateCompanyDetails} />
                    </div>



                </>
            }
            

        </div>
    );
}

export default getCompanyDetails;