import { createDarkTheme, createLightTheme } from '@fluentui/react-components';
import type { BrandVariants, Theme } from '@fluentui/react-components';

const brandPowerON: BrandVariants = {
  10: "#040206",
  20: "#1B1327",
  30: "#2C1C46",
  40: "#3B2460",
  50: "#492B7C",
  60: "#573989",
  70: "#644792",
  80: "#72559B",
  90: "#7F64A4",
  100: "#8C73AE",
  110: "#9982B7",
  120: "#A692C0",
  130: "#B3A2CA",
  140: "#C1B2D3",
  150: "#CEC2DD",
  160: "#DBD2E6"
};

const lightPowerONTheme: Theme = {
  ...createLightTheme(brandPowerON),
};

const darkPowerONTheme: Theme = {
  ...createDarkTheme(brandPowerON),
};

// darkTheme.colorBrandForeground1 = brandPowerON[110]; // use brand[110] instead of brand[100]
// darkTheme.colorBrandForeground2 = brandPowerON[120]; // use brand[120] instead of brand[110]

export { brandPowerON, lightPowerONTheme, darkPowerONTheme };