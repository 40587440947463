import { Spinner, makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';

interface Props {
    message: string;
}

const useStyles = makeStyles({
    container: {
        "> div": { ...shorthands.padding("20px") },
    },
});

const LoadingPage: React.FC<Props> = (props) => {
    const styles = useStyles();
    const { message } = props;

    return (
        <div className={styles.container}>
            <Spinner size="huge" labelPosition="below" label={message} />
        </div>
    );
};

export default LoadingPage;