import { useState, useCallback, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useSignInState } from '../Components/signInState';

interface useFetchProps {
  scope: string;
}

interface FetchAPIProps {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | undefined;
  body?: Record<string, unknown>;
}

interface UseFetchReturn {
  fetchAPI: (props: FetchAPIProps) => Promise<void>;
  isLoading: boolean;
  serverError: Error | null;
  apiData: Record<string, string | number | boolean | undefined> | null;
}

const useFetch = ({ scope }: useFetchProps): UseFetchReturn => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { accounts, instance, inProgress } = useMsal();
  const { signInState } = useSignInState();

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<Error | null>(null);
  const [apiData, setApiData] = useState<Record<string, string | number | boolean | undefined> | null>(null);

  const getAuthHeader = async (): Promise<{ headers: { "Content-Type": string; "Authorization": string } }> => {
    let currentCustomerTid = signInState.customerInfo.find((customer: { CustomerID: number; }) => customer.CustomerID === signInState.currentCustomerID)?.TenantID
    if (inProgress !== 'none' && !accounts.length) {
      // Wait for 1 second before trying again
      await new Promise(resolve => setTimeout(resolve, 1000));
      return getAuthHeader();
    }
    try {
      const silentResult = await instance.acquireTokenSilent({
        scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/${scope}`],
        account: accounts[0],
      });
      return {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${silentResult.accessToken}`,
          ...(currentCustomerTid && { "x-customertid": currentCustomerTid })
        },
      };
    } catch (error) {
      if ((error as any).name === "InteractionRequiredAuthError") {
        const interactiveResult = await instance.acquireTokenPopup({
          scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/${scope}`],
          account: accounts[0],
        });
        return {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${interactiveResult.accessToken}`,
            ...(currentCustomerTid && { "x-customertid": currentCustomerTid })
          },
        };

      } else {
        throw error;
      }
    }
  };

  const fetchAPI = useCallback(async ({ url, method, body }: FetchAPIProps) => {
    setIsLoading(true);
    try {
      const header = await getAuthHeader();
      // console.log('[useFetchAPI] header:', header);
      const fullUrl = url.startsWith('http') ? url : `${apiUrl}${url}`;
      const response = await fetch(fullUrl, {
        // ...header,
        headers: header.headers,
        method: method,
        ...(body && { body: JSON.stringify(body) })
      });

      // if (!response.ok) {
      //   const errorText = await response.text();
      //   throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      // }

      const data = await response.json();
      setApiData(data);
    } catch (error) {
      if (error instanceof Error) {
        setServerError(error);
      } else {
        setServerError(new Error('An unknown error occurred'));
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [apiUrl, getAuthHeader]);

  return { fetchAPI, isLoading, serverError, apiData };
};

export default useFetch;