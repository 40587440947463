import React, { ReactNode } from 'react';

interface MainContentProps {
  children: ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => (
  <div className="mainContent">
    {children}
  </div>
);

export default MainContent;