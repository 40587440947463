import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {
    Text,
    Label,
    Button,
    Input,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    makeStyles,
    shorthands,
    Title1,
    Title2,
    Title3,
    Subtitle2,
    Card,
} from "@fluentui/react-components";
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
} from "@fluentui/react-components";

// import useFetch from '../Hooks/useFetch2';
import useFetch from '../Hooks/useFetchAPI';
import { useAuthHeader } from '../Components/getAuthHeader';
import { useSignInState } from './signInState';

import type { PopoverProps, PositioningImperativeRef } from "@fluentui/react-components";
import LoadingPage from './LoadingPage';

const useStyles = makeStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...shorthands.gap('1rem'),
        ...shorthands.padding('2rem'),
        boxSizing: 'border-box',
    },
    columnsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        ...shorthands.gap('20px'),
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        width: '33%',
    },
    welcomeText: {
        fontSize: '2rem',
        textAlign: 'center',
    },
    instructionText: {
        fontSize: '1.2rem',
        textAlign: 'center',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    // other styles...
});

interface SignUpFormNewUserProps {
    Token: string | null;
}

interface FormFields {
    UserTenantID: string;
    UserObjectID: string;
    CustomerID: number;
    CompanyName: string;
    ContactName: string;
    ContactEmail: string;
    //homeAccountId: string;
    UserName: string;
    UserEmail: string;
    FirstName: string;
    LastName: string;
    DisplayName: string;
    PhoneNumber: string;
    IsActive: boolean;
    ExistingUser: boolean;
    Token: string;
}

type InviteInfo = {
    InviteID: number;
    CustomerID: number;
    InvitedByUserID: number;
    InvitedByUserEmail: string;
    UserEmail: string;
    CreatedDatetime: string;
    ExpiryDate: string;
    AcceptedDatetime: string | null;
    Token: string;
    Status: string;
    IsValid: boolean;
};

type InvitedByUser = {
    UserName: string;
    Email: string | null;
    FirstName: string | null;
    LastName: string | null;
    DisplayName: string | null;
};

type InvitedByCustomer = {
    CompanyName: string;
};

interface ExistingUser {
    UserID: number;
    UserName: string;
    Email: string;
    FirstName: string;
    LastName: string | null;
    DisplayName: string;
    PhoneNumber: string;
    IsActive: boolean;
}

interface ExistingCustomers {
    CustomerID: number;
    IsActive: boolean;
}
type InviteDetails = {
    inviteInfo: InviteInfo[];
    invitedByUser: InvitedByUser[];
    invitedByCustomer: InvitedByCustomer[];
    existingUser: ExistingUser[];
    existingCustomers: ExistingCustomers[];
};

const SignUpFormNewUser: React.FC<SignUpFormNewUserProps> = ({ Token }) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const positioningRef = React.useRef<PositioningImperativeRef>(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const { accounts, instance } = useMsal();
    const styles = useStyles();
    const navigate = useNavigate();

    const { signInState } = useSignInState();
    // const { fetchAPI, isLoading, serverError, apiData } = useFetch({ scope: 'AppUser' });
    const { fetchAPI, apiData, isLoading, serverError } = useFetch({ scope: 'AppUser' })
    const { fetchAPI: submitForm, apiData: submitData, isLoading: isLoadingSubmit, serverError: serverErrorSubmit } = useFetch({ scope: 'AppUser' })

    const [currentTheme, setCurrentTheme] = useState('lightmodecontainer');

    const [profileData, setProfileData] = useState<InviteDetails>();
    const [existingUser, setExistingUser] = useState<boolean>(false);
    const [inviteIsValid, setInviteIsValid] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showPopover, setShowPopover] = useState(false);
    const [popOverMessage, setpopOverMessage] = useState('');
    const [inviteComplete, setInviteComplete] = useState<boolean>(false);


    useEffect(() => {
        setCurrentTheme(signInState?.userInfo[0]?.ThemePreference === 'dark' ? 'Dark' : 'Light');
    }, [signInState]);

    // const getAuthHeader = useAuthHeader('AppUser');

    const [formData, setFormData] = useState<FormFields>({
        UserTenantID: accounts[0].tenantId,
        UserObjectID: accounts[0].localAccountId,
        UserName: accounts[0].username,
        CustomerID: 0,
        CompanyName: '',
        ContactName: '',
        ContactEmail: '',
        UserEmail: '',
        FirstName: '',
        LastName: '',
        DisplayName: '',
        PhoneNumber: '',
        IsActive: true,
        ExistingUser: false,
        Token: '',
    });

    useEffect(() => {
        if (Token === null) {
            setErrorMessage("Sorry, please click a link to redeem an invite");
            return;
        }

        const fetchProfileData = async () => {
            const silentResult = await instance.acquireTokenSilent({
                scopes: ["User.Read"],
                account: accounts[0]
            });

            const profileResponse = await fetch('https://graph.microsoft.com/v1.0/me/', {
                headers: {
                    Authorization: `Bearer ${silentResult.accessToken}`
                }
            });

            if (profileResponse.ok) {
                const profileData = await profileResponse.json();
                console.log('Profile data:', profileData);
                // setProfileData(profileDataResponse);

                setFormData({
                    ...formData,
                    UserEmail: profileData.mail || '',
                    FirstName: profileData.givenName || '',
                    LastName: profileData.surname || '',
                    DisplayName: profileData.displayName || '',
                    PhoneNumber: profileData.businessPhones[0] || '',
                    Token: Token || '',
                });

            } else {
                console.error('Failed to fetch profile data:', profileResponse.status);
            }
        };

        const fetchInviteDetails = async () => {
            await fetchAPI({ url: `invite/getinvitedetails?token=${Token}&userid=${accounts[0].localAccountId}`, method: 'GET' });
        }

        fetchProfileData();
        fetchInviteDetails();
    }, [Token, accounts, instance]);

    useEffect(() => {
        if (!isLoading && !serverError && apiData) {
            console.log('apiData:', apiData);

            if (apiData.message === 'Invite is not valid') {
                setInviteIsValid(false);
                setErrorMessage('Sorry, this invite is not valid.');
                return;
            }
            if (apiData.message === 'Invite already accepted') {
                setInviteIsValid(false);
                setInviteComplete(true);
                setErrorMessage('Sorry, this invite has already been accepted.');
                return;
            }
            if (apiData.message === 'No invite info found') {
                setInviteIsValid(false);
                setErrorMessage('Sorry, this invite could not be found.');
                return;
            }
            let inviteDetailsData: InviteDetails | null = null;
            if (apiData && (apiData as unknown as { result: InviteDetails }).result) {
                inviteDetailsData = (apiData as unknown as { result: InviteDetails }).result;
                setProfileData(inviteDetailsData)
                // console.log('Settings Invite details:', inviteDetailsData);
                setInviteIsValid(true);
                if (inviteDetailsData?.existingUser.length > 0) {
                    setExistingUser(true);
                }

                let contactName =
                    (inviteDetailsData?.invitedByUser[0]?.FirstName && inviteDetailsData?.invitedByUser[0]?.LastName ? inviteDetailsData?.invitedByUser[0]?.FirstName + ' ' + inviteDetailsData?.invitedByUser[0]?.LastName : null) ||
                    inviteDetailsData?.invitedByUser[0]?.DisplayName ||
                    inviteDetailsData?.invitedByUser[0]?.Email ||
                    inviteDetailsData?.invitedByUser[0]?.UserName;

                setFormData({
                    ...formData,
                    CustomerID: inviteDetailsData?.inviteInfo[0]?.CustomerID || 0,
                    CompanyName: inviteDetailsData?.invitedByCustomer?.[0]?.CompanyName || '',
                    ContactName: contactName || '',
                    ContactEmail: inviteDetailsData?.invitedByUser?.[0]?.Email || '',
                    ExistingUser: existingUser,
                });
            }
        }
    }, [isLoading, serverError, apiData]);

    // useEffect(() => {
    //     console.log('formData has been updated:', formData);
    // }, [formData]);

    useEffect(() => {
        if (buttonRef.current) {
            positioningRef.current?.setTarget(buttonRef.current);
        }
    }, [buttonRef, positioningRef]);

    const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) => setShowPopover(data.open || false);

    const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSignUp = async (e: React.FormEvent) => {
        console.log('Form submitted:', JSON.stringify(formData));
        e.preventDefault();
        setShowPopover(false); // Hide the popover initially

        if (formData.UserEmail.toLowerCase() !== profileData?.inviteInfo[0].UserEmail.toLowerCase()) {
            setpopOverMessage("You must use the same email address as the invite was created for.")
            setShowPopover(true)
            return
        }

        try {

            if (existingUser) {
                console.log('[RegisterUser] Adding user to customer:');
                await submitForm({ url: `${apiUrl}invite/addusertocustomer?userId=${formData.UserObjectID}&customerId=${formData.CustomerID}&token=${formData.Token}`, method: 'PUT', body: { formData } });

            } else {
                console.log('[RegisterUser] Creating new user:');
                await submitForm({ url: `${apiUrl}invite/createuser`, method: 'POST', body: { formData } });

            }
        } catch (error) {
            // Handle hard errors
            console.error('Error submitting form:', error);
        }
    };

    useEffect(() => {
        if (!isLoadingSubmit && !serverErrorSubmit && submitData) {
        if (submitData.message === 'User already added to this customer') {
            setpopOverMessage(submitData.message);
            setShowPopover(true);
            return;
        }
        else if (submitData.message === 'Error creating user, check provided information.') {
            console.log('[RegisterUser] Error creating user, check provided information.');
            setpopOverMessage(submitData.message);
            setShowPopover(true);
            return
        }
        else {
            console.log('[RegisterUser] Customer added successfully', submitData.message);
            setInviteComplete(true);
            return
        }
    }

    if (!isLoadingSubmit && serverErrorSubmit && submitData) {
        console.error('[RegisterUser] Failed Response:', serverErrorSubmit);
        setpopOverMessage(serverErrorSubmit.message);
        setShowPopover(true);
    }
    }, [isLoadingSubmit, serverErrorSubmit, submitData]);

    return (
        <div className={styles.container}>
            {inviteComplete ?
                <Dialog modalType="alert"
                    open={inviteComplete}
                    onOpenChange={(event, data) => {
                        setInviteComplete(data.open);
                    }}
                >
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Invite redeemed!</DialogTitle>
                            <DialogContent>
                                Your invite has been successfully redeemed. You can now sign in to Additionality.
                            </DialogContent>
                            <DialogActions>
                                <Button appearance="primary" onClick={() => window.location.href = '/'}>Login</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
                :
                <>
                    {errorMessage ? <div>{errorMessage}</div> :

                        isLoading ? <LoadingPage message="Loading invite information..." /> :

                            !inviteIsValid ?
                                <>
                                    <Text className={styles.instructionText}>Whoops! It looks like there's something not quite right with this invite...</Text>
                                    <Text className={styles.instructionText}>{errorMessage}</Text>
                                </> :
                                <>
                                    {!existingUser ?
                                        <>
                                            <Text className={styles.instructionText}>Welcome, it looks like this will be your first time using Additionality.</Text>
                                            <Text className={styles.instructionText}>Please complete the form below to create a new account.</Text>
                                        </>
                                        :
                                        <>
                                            <Text className={styles.instructionText}>Welcome back{' ' + profileData?.existingUser[0]?.DisplayName}!</Text>
                                            <Text className={styles.instructionText}>Please check the details below to add a new customer to your account.</Text>
                                        </>
                                    }
                                    <Card>
                                        {/* className={styles['form'+currentTheme as keyof typeof styles]} */}
                                        <form onSubmit={handleSignUp}>
                                            {existingUser ?
                                                <>
                                                    <Title1>Add customer</Title1>
                                                    <p></p>
                                                    <div key="InviteDetails">
                                                        <Title3>Invite details</Title3>
                                                        <p></p>
                                                        <Subtitle2 >Company name: </Subtitle2>
                                                        <Text>{formData.CompanyName}</Text>
                                                        <p></p>
                                                        {/* <Input disabled type="text" name="CompanyName" value={formData.CompanyName} onChange={handleChange} placeholder="Company Name" required style={{ width: '100%' }} /> */}
                                                        <Subtitle2>Main contact name: </Subtitle2>
                                                        <Text>{formData.ContactName}</Text>
                                                        <p></p>
                                                        {/* <Input disabled type="text" name="ContactName" value={formData.ContactName} onChange={handleChange} placeholder="Contact Name" required style={{ width: '100%' }} /> */}
                                                        <Subtitle2>Main contact email: </Subtitle2>
                                                        <Text>{formData.ContactEmail}</Text>
                                                        {/* <Input disabled type="email" name="ContactEmail" value={formData.ContactEmail} onChange={handleChange} placeholder="Email" required style={{ width: '100%' }} /> */}
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <Title1>Create account</Title1>
                                                    <p></p>
                                                    <div className={styles.columnsContainer}>
                                                        <div key="AccountDetails" className={styles.column}>
                                                            <Title3>Account details</Title3>
                                                            <Label>Microsoft Tenant ID</Label>
                                                            <Input disabled type="text" name="tenantId" value={formData.UserTenantID} style={{ width: '100%' }} />
                                                            <Label>User Object ID</Label>
                                                            <Input disabled type="text" name="localAccountId" value={formData.UserObjectID} style={{ width: '100%' }} />
                                                            <Label>User name</Label>
                                                            <Input disabled type="text" name="username" value={formData.UserName} style={{ width: '100%' }} />
                                                        </div>
                                                        <div key="InviteDetails" className={styles.column}>
                                                            <Title3>Invite details</Title3>
                                                            <Label>Company name</Label>
                                                            <Input disabled type="text" name="CompanyName" value={formData.CompanyName} onChange={handleChange} placeholder="Company Name" required style={{ width: '100%' }} />
                                                            <Label>Main contact name</Label>
                                                            <Input disabled type="text" name="ContactName" value={formData.ContactName} onChange={handleChange} placeholder="Contact Name" required style={{ width: '100%' }} />
                                                            <Label>Main contact email</Label>
                                                            <Input disabled type="email" name="ContactEmail" value={formData.ContactEmail} onChange={handleChange} placeholder="Email" required style={{ width: '100%' }} />
                                                        </div>
                                                        <div key="NewUserDetails" className={styles.column}>
                                                            <Title3>User details</Title3>
                                                            <Label required>First Name</Label>
                                                            <Input type="text" name="FirstName" value={formData.FirstName} onChange={handleChange} placeholder="First Name" required style={{ width: '100%' }} />
                                                            <Label required>Last Name</Label>
                                                            <Input type="text" name="LastName" value={formData.LastName} onChange={handleChange} placeholder="Last Name" required style={{ width: '100%' }} />
                                                            <Label required>Display Name</Label>
                                                            <Input type="text" name="DisplayName" value={formData.DisplayName} onChange={handleChange} placeholder="Display Name" required style={{ width: '100%' }} />
                                                            <Label required>Email Address</Label>
                                                            <Input type="email" name="UserEmail" value={formData.UserEmail} onChange={handleChange} placeholder="Email Address" required style={{ width: '100%' }} />
                                                            {/* <InfoLabel info="Admins and the person who invited you are the only people that will see this" required>Telephone number</InfoLabel> */}
                                                            <Label required>Telephone number</Label>
                                                            <Input type="tel" name="PhoneNumber" value={formData.PhoneNumber} onChange={handleChange} placeholder="Phone Number" required style={{ width: '100%' }} />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <Popover withArrow open={showPopover} positioning={{ positioningRef }} mouseLeaveDelay={1} closeOnIframeFocus closeOnScroll onOpenChange={handleOpenChange}>
                                                <PopoverTrigger disableButtonEnhancement>
                                                    <Button appearance='primary' ref={buttonRef} style={{ margin: '10px auto 10px', display: 'block' }} type="submit">{existingUser ? "Add Customer" : "Sign up"}</Button>
                                                </PopoverTrigger>
                                                <PopoverSurface tabIndex={-1}>
                                                    <div style={{ padding: '10px' }}> {popOverMessage}</div>
                                                </PopoverSurface>
                                            </Popover>
                                        </form>
                                    </Card>
                                </>
                    }
                </>
            }
        </div>

    )
};

export default SignUpFormNewUser;