import {
    Card,
    Text,
    Persona,
    TableBody,
    TableCell,
    TableRow,
    Table,
    TableHeader,
    TableHeaderCell,
    Textarea,
    Button,
    Switch,
    makeStyles,
    Input
} from "@fluentui/react-components";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { DismissRegular, EditRegular, SaveEditRegular } from "@fluentui/react-icons";

import { useSignInState } from './Components/signInState';
import { useGetProfilePic } from './Components/getProfilePic';
import useUpdateUserProfile from './Components/updateUserProfile';

type UserInfoType = {
    FirstName: string;
    LastName: string;
    DisplayName: string;
    PhoneNumber: string;
};

const useStyles = makeStyles({
    profileCardHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    fullWidthInput: {
        width: '100%',
    },
});

const profilePage = () => {
    const { inProgress, accounts } = useMsal();
    const styles = useStyles();
    const { signInState, setSignInState } = useSignInState();
    const { getProfilePic } = useGetProfilePic();
    const [profilePic, setProfilePic] = useState<string | undefined>(undefined);
    const { updateUserProfile } = useUpdateUserProfile();

    const userInfo = signInState.userInfo;
    const customerInfo = signInState.customerInfo;
    const userFeatures = signInState.userFeatures;
    const userRoles = signInState.userRoles;

    useEffect(() => {
        const fetchProfilePic = async () => {
            const pic = await getProfilePic();
            setProfilePic(pic);
        };

        if (inProgress === "none" && accounts.length > 0) {
            fetchProfilePic();
        }
    }, [inProgress, accounts]);

    const headerStyle = {
        //fontSize: '15px', 
        fontWeight: 'bold',
    };

    const [editableData, setEditableData] = useState<UserInfoType>({
        FirstName: userInfo[0]?.FirstName,
        LastName: userInfo[0]?.LastName,
        DisplayName: userInfo[0]?.DisplayName,
        PhoneNumber: userInfo[0]?.PhoneNumber,
    });

    const [isEditing, setIsEditing] = useState<{ [K in keyof Partial<UserInfoType>]: boolean }>({
        DisplayName: false,
        FirstName: false,
        LastName: false,
        PhoneNumber: false,
    });

    // Handles showing the text area for editing
    const handleEditClick = (field: keyof UserInfoType) => {
        setIsEditing({ ...isEditing, [field]: true });
    };

    // Handles saving the edited data
    const handleSaveClick = (field: keyof UserInfoType) => {
        console.log("Saving data for field:", field, editableData[field]);
        updateUserProfile(field, editableData[field], signInState.userInfo[0].UserID);
        setIsEditing({ ...isEditing, [field]: false });// Need to trap and handle errors
        setSignInState(prevState => {
            // Create a new userInfo object with the updated property
            const newUserInfo = {
                ...prevState.userInfo[0],
                [field]: editableData[field]
            };

            // Return the new state with the updated userInfo
            return {
                ...prevState,
                userInfo: [newUserInfo, ...prevState.userInfo.slice(1)]
            };
        });
    };

    const handleChange = (field: keyof UserInfoType, value: string) => {
        setEditableData(prev => ({ ...prev, [field]: value }));
    };

    const switchTheme = (checked: boolean) => {
        const themePreference = checked ? "dark" : "light";
        updateUserProfile("ThemePreference", themePreference, signInState.userInfo[0].UserID)
        setSignInState(prevState => {
            // Create a new userInfo object with the updated property
            const newUserInfo = {
                ...prevState.userInfo[0],
                ThemePreference: themePreference
            };

            // Return the new state with the updated userInfo
            return {
                ...prevState,
                userInfo: [newUserInfo, ...prevState.userInfo.slice(1)]
            };
        });

    }

    if (!accounts[0]) {
        console.log('[ProfilePage] No accounts found, user not logged in. Redirecting to login page.');
        return <div>Please log in to view your profile.</div>;
    }

    return (
        <div style={{ margin: '20px' }}>
            <Card>
                <div className={styles.profileCardHeader}>
                    <Text size={600} weight="semibold">User Profile</Text>
                    <Button
                        appearance="subtle"
                        aria-label="Close"
                        icon={<DismissRegular />}
                        onClick={() => window.history.back()}
                    />
                </div>
                <Persona
                    key={userInfo[0].UserID}
                    name={userInfo[0].DisplayName || accounts[0].name}
                    secondaryText={userInfo[0].Email}
                    size={"huge"}
                    avatar={{
                        image: {
                            src: profilePic || 'https://via.placeholder.com/48',
                        },
                    }}
                />
                <Switch
                    key="ThemePreference"
                    label={"Dark Mode"}
                    defaultChecked={userInfo[0].ThemePreference === "dark" ? true : false} // this is when the current user is allowed to edit the feature
                    labelPosition="after"
                    onChange={(event, checked) => {switchTheme(checked.checked)}}
                />
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>User Info</TableHeaderCell>
                            <TableHeaderCell>Value</TableHeaderCell>
                            <TableHeaderCell>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {(["DisplayName", "FirstName", "LastName", "PhoneNumber"] as (keyof UserInfoType)[]).map((key) => (
                            <TableRow key={key}>
                                <TableCell>{key}</TableCell>
                                <TableCell>
                                    {isEditing[key] ? (
                                        // <Textarea
                                        //     size="small"
                                        //     value={editableData[key] ? editableData[key].toString() : ''}
                                        //     onChange={(e) => handleChange(key, e.target.value)}
                                        // />
                                        <Input
                                            className={styles.fullWidthInput}
                                            size="medium"
                                            value={editableData[key] ? editableData[key].toString() : ''}
                                            onChange={(e) => handleChange(key, e.target.value)}
                                        />
                                    ) : (
                                        editableData[key]
                                    )}
                                </TableCell>
                                <TableCell>
                                    {isEditing[key] ? (
                                        <Button icon={<SaveEditRegular />} onClick={() => handleSaveClick(key)} />
                                    ) : (
                                        <Button icon={<EditRegular />} onClick={() => handleEditClick(key)} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Text size={400} weight="semibold" style={{ marginTop: '20px' }}>Customer Details</Text>
                {customerInfo.map((customer) => (
                    <div key={customer.CustomerID}>
                        <Text weight="semibold" underline>{customer.CompanyName}</Text>
                        <div>
                            <Text size={300} weight="semibold" style={{ marginTop: '20px' }}>Features</Text>
                            <div>
                                {
                                    userFeatures.filter(feature => feature.CustomerID === customer.CustomerID).length > 0 ?
                                        userFeatures.filter(feature => feature.CustomerID === customer.CustomerID).map((feature) => (
                                            // add a conditional to check if the current user is allowed to edit the feature
                                            <Switch
                                                key={feature.FeatureID}
                                                label={feature.FeatureName}
                                                checked={feature.Enabled} // this is when the current user isn't allowed to edit the feature
                                                //defaultChecked={feature.Enabled} // this is when the current user is allowed to edit the feature
                                                labelPosition="before"
                                                onChange={() => { }} // need to add a function to handle the change and save the new value
                                            />
                                        ))
                                        :
                                        <Text>No assigned features</Text>
                                }
                            </div>
                        </div>
                        <div>
                            <Text size={300} weight="semibold" style={{ marginTop: '20px' }}>Roles</Text>
                            <div>
                                {
                                    userRoles.filter(role => role.CustomerID === customer.CustomerID).length > 0 ?
                                        userRoles.filter(role => role.CustomerID === customer.CustomerID).map((role) => (
                                            <Text key={role.RoleID}>{role.RoleName}</Text>
                                        ))
                                        :
                                        <Text>No assigned roles</Text>
                                }
                            </div>
                        </div>
                    </div>
                ))}


            </Card>
        </div>
    );
};

export default profilePage;