import { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useSignInState } from './signInState';
import { loginRequest } from './authConfig';
import authErrorHandling from './authErrorHandling';
// import { useAuthHeader } from './getAuthHeader';

//import useUpdateUserDetails from './updateUserDetails';


export const useHandleLogin = () => {
  const { instance, inProgress } = useMsal();
  const { setSignInState } = useSignInState();
  // const getAuthHeader = useAuthHeader('AppUser', 'POST');
  
  const [authError, setauthError] = useState<string>('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async () => {
    try {
      const loginResponse = await instance.loginPopup(loginRequest)

      const accounts = instance.getAllAccounts();
      console.log('[HandleLogin] Login response:', loginResponse);
      
      const silentResult = await instance.acquireTokenSilent({
        scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/AppUser`],
        account: accounts[0],
      });

      const response = await fetch(`${apiUrl}initial/signin`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${silentResult.accessToken}`,
        },
      });
   
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setSignInState({ ...data });

    } catch (error) {
      setauthError(authErrorHandling(error));
      console.log('[HandleLogin] Login error:', error);
    }

  }

  return { handleLogin };
};