import { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler, Title3 } from '@fluentui/react-components';

import useFetch from '../Hooks/useFetchAPI';
import { useSignInState } from './signInState';

const LicenseDetails = () => {
    const { signInState } = useSignInState();
    const { fetchAPI: fetchLicenses, apiData: licenseData, isLoading: isLoadingLicenses, serverError: serverErrorLicenses } = useFetch({ scope: 'AdminUser' })

    const [licenseInfo, setlicenseInfo] = useState<LicenseDataResult[] | null>(null);

    useEffect(() => {
        const fetchLicensesAsync = async () => {
            await fetchLicenses({ url: 'admin/getlicenses', method: 'GET' });
        };

        fetchLicensesAsync()
    }, [signInState.currentCustomerID]);

    useEffect(() => {
        if (!isLoadingLicenses && !serverErrorLicenses && licenseData) {
            let licenseDataResults: LicenseDataResult[] | null = null;
            if (licenseData && (licenseData as unknown as { result: LicenseDataResult[] }).result) {
                licenseDataResults = (licenseData as unknown as { result: LicenseDataResult[] }).result;
                console.log(licenseDataResults);
                setlicenseInfo(licenseDataResults)
            }
        }
    }, [isLoadingLicenses, serverErrorLicenses, licenseData]);

    useEffect(() => {
        console.log('licenseInfo:', licenseInfo);
    }, [licenseInfo]);

    return (
        <div style={{ marginLeft: '20px' }}>  
            {isLoadingLicenses && <div>Loading Licenses...</div>}
            {licenseInfo &&
                <>
                    <Title3>Licensing Information</Title3>
                    <table>
                        <thead>
                            <tr>
                                <th>License Name</th>
                                <th>Start Date</th>
                                <th>Expiry Date</th>
                                <th>License Type</th>
                                <th>Status</th>
                                <th>Modules</th>
                            </tr>
                        </thead>
                        <tbody>
                            {licenseInfo.map((license, index) => (
                                <tr key={index}>
                                    <td>{license.DisplayName}</td>
                                    <td>{new Date(license.StartDate).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                    <td>{new Date(license.ExpiryDate).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                    <td>{license.Type}</td>
                                    <td>{license.IsActive ? 'Active' : 'Disabled'}</td>
                                    <td>
                                        <ul>
                                            {license.modules.map((module, index) => (
                                                <li key={index}>{module.DisplayName}</li>
                                            ))}
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            }
        </div>
    );
}

export default LicenseDetails;