import { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler, Title3 } from '@fluentui/react-components';

import { useSignInState } from './signInState';

import ModuleConfigIntuneReporting from './ModuleConfigs/Module-IntuneReporting';
import ModuleWUfBReporting from './ModuleConfigs/Module-WUfBReporting';
import ModuleDellWarranty from './ModuleConfigs/Module-DellWarranty';
import ModuleLenovoWarranty from './ModuleConfigs/Module-LenovoWarranty';
//import more feature configuration components here

const featureConfiguration = () => {
    const { signInState } = useSignInState();

    const [openItems, setOpenItems] = useState(["0"]);
    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems);
    };

    const checkModule = useCallback((moduleName: string): boolean => {
        return signInState.licenseInfo.some(license => 
          license.CustomerID === signInState.currentCustomerID &&
          license.modules.some(module => module.name === moduleName)
        );
    }, [signInState]);

    useEffect(() => {
        setOpenItems(["0"]);
    }, [signInState.currentCustomerID]);

    return (
        <div style={{ marginLeft: '20px' }}>  
            {/* Depending on licensed features, render appropriate feature configurations under accordions */}
            <Accordion
                openItems={openItems}
                onToggle={handleToggle}
                collapsible
            >
                <AccordionItem disabled={!checkModule('Reporting_Intune')} value="1">
                    <AccordionHeader>Intune & Entra Reporting Configuration</AccordionHeader>
                    <AccordionPanel>
                        <ModuleConfigIntuneReporting />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem disabled={!checkModule('Reporting_Intune')} value="2">
                    <AccordionHeader>WUfB Reporting Configuration</AccordionHeader>
                    <AccordionPanel>
                        <ModuleWUfBReporting />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem disabled={!checkModule('Reporting_Intune')} value="3">
                    <AccordionHeader>Dell Warranty Configuration</AccordionHeader>
                    <AccordionPanel>
                        <ModuleDellWarranty />
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem disabled={!checkModule('Reporting_Intune')} value="4">
                    <AccordionHeader>Lenovo Warranty Configuration</AccordionHeader>
                    <AccordionPanel>
                        <ModuleLenovoWarranty />
                    </AccordionPanel>
                </AccordionItem>
                
                <AccordionItem disabled={!checkModule('M365LCM_Intune' || 'M365LCM_CA')} value="5">
                    <AccordionHeader>Lifecycle Management Configuration</AccordionHeader>
                    <AccordionPanel>
                        LCM Configuration Details
                        Coming soon...
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem disabled={!checkModule('AVD_Base')} value="6">
                    <AccordionHeader>Azure Virtual Desktop Configuration</AccordionHeader>
                    <AccordionPanel>
                        AVD Configuration Details
                        Coming soon...
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
}

export default featureConfiguration;