import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    shorthands,
    Button,
    Caption1,
    tokens,
    Text,
    Title1,
    Card,
    CardHeader,
    CardPreview,
    CardProps,
    Title3,
} from '@fluentui/react-components';
import { MoreHorizontal20Regular } from "@fluentui/react-icons";

import { useSignInState } from './Components/signInState';
import ServiceHealthHome from './Components/ServiceHealth';

const useStyles = makeStyles({
    main: {
        ...shorthands.gap("16px"),
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        alignItems: "flex-start",
    },
    cards: {
        gridColumnStart: "1",
        gridColumnEnd: "span 3",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
        ...shorthands.gap("16px"),
    },
    card: {
        width: "400px",
        maxWidth: "100%",
        height: "fit-content",
    },
    serviceHealth: {
        gridColumnStart: 4,
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },

    smallRadius: {
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
    },

    grayBackground: {
        backgroundColor: tokens.colorNeutralBackground3,
    },

    logoBadge: {
        ...shorthands.padding("5px"),
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        backgroundColor: "#FFF",
        boxShadow:
            "0px 1px 2px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
    },
});

interface SelectableCardProps extends CardProps {
    image: string;
    imagealt?: string;
    logo?: string;
    logoalt?: string;
    header: string;
    description?: string;
}

const SelectableCard: React.FC<SelectableCardProps> = (props) => {
    const styles = useStyles();

    return (
        <Card className={styles.card} {...props}>
            <CardPreview
                className={styles.grayBackground}
                logo={
                    <img
                        className={styles.logoBadge}
                        src={require('./Assets/' + (props.logo || "carddefaultlogo.png"))}
                        alt={props.logoalt || "Card Logo"}
                    />
                }
            >
                <img
                    className={styles.smallRadius}
                    src={require('./Assets/' + (props.image || "carddefaultimage.png"))}
                    alt={props.imagealt || "Card Image"}
                />
            </CardPreview>

            <CardHeader
                header={<Text weight="semibold">{props.header}</Text>}
                description={
                    <Caption1 className={styles.caption}>{props.description}</Caption1>
                }
                action={
                    <Button
                        appearance="transparent"
                        icon={<MoreHorizontal20Regular />}
                        aria-label="More actions"
                    />
                }
            />
        </Card>
    );
};

export const HomePage: React.FC = () => {
    const styles = useStyles();

    const { signInState } = useSignInState();
    const [currentCustomerID, setCurrentCustomerID] = useState<number | undefined>();

    const [selected1, setSelected1] = React.useState(false);
    const [selected2, setSelected2] = React.useState(false);

    const currentHour = new Date().getHours();
    let greeting;

    if (currentHour >= 3 && currentHour < 12) {
        greeting = "Good morning";
    } else if (currentHour >= 12 && currentHour < 17) {
        greeting = "Good afternoon";
    } else if (currentHour >= 17 && currentHour < 20) {
        greeting = "Good evening";
    } else {
        greeting = "Working late";
    }

    useEffect(() => {
        setCurrentCustomerID(signInState.currentCustomerID);
    }, [signInState]);

    // console.log('[HomePage] - SignInState: ', signInState);

    //style={{ padding: '20px' }}
    return (
        <>
            <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                <Title1> {greeting} {signInState.userInfo[0]?.FirstName}{greeting === 'Working late' && '?'} </Title1>
            </div>
            <div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
                <p />
                <Text>
                    Welcome to the Private Preview. This is a work in progress and we would love to hear your feedback, please use the feedback button in the bottom left corner.
                </Text>
                <p />
                <Text>
                    This home screen is a placeholder for now (the cards don't work...), we will be adding more features and functionality soon.
                </Text>
                <br />
                <Text>
                    Go explore the reporting for now and keep your eye on the home page as things will change quickly!
                </Text>
            </div>
            <div className={styles.main} style={{ paddingLeft: '20px' }}>

                <p />
                <div className={styles.cards}>
                    <SelectableCard
                        selected={selected1}
                        onSelectionChange={(_, { selected }) => setSelected1(selected)}
                        header="View Latest Windows Update Dashboard"
                        description="Custom dashboard updated 53m ago"
                        image="phone1.png"
                    />
                    <SelectableCard
                        selected={selected2}
                        onSelectionChange={(_, { selected }) => setSelected2(selected)}
                        header="Find devices failing to sync with Intune"
                        description="Custom dashboard updated 10m ago"
                        image=""
                    />
                    <SelectableCard
                        selected={selected2}
                        onSelectionChange={(_, { selected }) => setSelected2(selected)}
                        header="Coming soon... Backup and restore your Intune configuration"
                        description="View more roadmap items"
                        image=""
                    />
                </div>
                <div className={styles.serviceHealth}>

                    <ServiceHealthHome />
                </div>

            </div>
        </>
    );
};

