import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@fluentui/react-components';
import { SignOutRegular } from "@fluentui/react-icons";

import { useSignInState } from './Components/signInState';
import { useHandleLogout } from './Components/HandleLogout';

import RegisterCustomer from './Components/RegisterCustomer';

const SignUpFlow = () => {
    const { accounts } = useMsal();
    const { signInState } = useSignInState();
    const [isPending, setIsPending] = useState(true);
    const { handleLogout } = useHandleLogout();

    if (signInState.hasAccount) {
        return (
            <div>
                <h1>User Account is Inactive</h1>
                <Button appearance='primary' size="medium" icon={<SignOutRegular />} onClick={handleLogout} >
                    Logout
                </Button>
            </div>
        )
    }

    if (!signInState.hasAccount && signInState.customerInfo.length > 0) {
        return (
            <div>
                <h1>No user account found - Speak to your account admin</h1>
             {/* Add a button to request access */}
            </div>
        )
    }

    if (signInState.customerInfo.length == 0) {
        return (
            <div>
                <h1>No customer account found - Register now</h1>
             <RegisterCustomer />   
            </div>
        )
    }

    console.log('signInState.customerInfo length:', signInState.customerInfo.length);
    if (signInState.customerInfo.length >0 && !signInState.customerInfo.some(company => company.IsActive)) {
        return (
            <div>
                <h1>Customer Account is Inactive</h1>
             {/* Redirect to buying a license again   */}
            </div>
        )
    }

    return null;
};

export default SignUpFlow;