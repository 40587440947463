import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Accordion, AccordionItem, AccordionHeader, AccordionPanel, AccordionToggleEventHandler,
    Button,
    makeStyles, shorthands, tokens,
    Drawer,
    DrawerHeaderTitle,
} from '@fluentui/react-components';
import { ArrowClockwiseDashesRegular, ArrowSyncCheckmarkRegular, BuildingRegular, ChartMultipleRegular, DismissRegular, LineHorizontal3Regular, LineHorizontal5Regular, SettingsRegular, ViewDesktopRegular } from '@fluentui/react-icons';

import { useSignInState } from '../Components/signInState';

const useStyles = makeStyles({
    drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('10px'),
        ...shorthands.padding("20px"),
    },
});

const SideNav = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const { signInState } = useSignInState();
    const [isExpanded, setIsExpanded] = useState(true);
    const [openItems, setOpenItems] = useState(["0"]);
    const [isCreateDashDisabled, setIsCreateDashDisabled] = useState(true);

    const handleAccordionToggle: AccordionToggleEventHandler<string> = (event, data) => {
        console.log('data:', data);
        setOpenItems(data.openItems);
    };

    const toggleDrawer = () => {
        setIsExpanded(!isExpanded);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, isExpanded ? 450 : 0);
    }

    const checkModule = useCallback((moduleName: string): boolean => {
        return signInState.licenseInfo.some(license =>
            license.CustomerID === signInState.currentCustomerID &&
            license.modules.some(module => module.name === moduleName)
        );
    }, [signInState]);

    const checkAdmin = useCallback((): boolean => {
        return signInState.userFeatures.some(feature =>
            (feature.FeatureID === 1 || feature.FeatureID === 2) &&
            feature.CustomerID === signInState.currentCustomerID
        );
    }, [signInState]);

    const checkReportingLicense = useCallback((): boolean => {
        return signInState.licenseInfo.some(license =>
            license.CustomerID === signInState.currentCustomerID &&
            license.IsActive === true &&
            license.modules.some(module => module.name.startsWith("Reporting"))
        );
    }, [signInState]);

    const checkCreateDash = useCallback((): boolean => {
        // Check if the user for the currentCustomerID has the Create Dashboards feature and it's enabled
        const feature = signInState.userFeatures.find(feature =>
            feature.FeatureID === 3 &&
            feature.CustomerID === signInState.currentCustomerID &&
            feature.Enabled === true
        );
        return Boolean(feature && checkReportingLicense());
    }, [signInState.currentCustomerID, checkReportingLicense]);


    useEffect(() => {
        setOpenItems(["0"]);
    }, [signInState.currentCustomerID]);

    useEffect(() => {
        setIsCreateDashDisabled(!checkCreateDash());
    }, [signInState]);

    return (
        <>
            <Button icon={<LineHorizontal3Regular />} onClick={toggleDrawer} />
            <Drawer
                open={isExpanded}
                // onOpenChange={handleDrawerChange}
                size="small"
                position="start"
                type="inline"
                separator
            >
                <DrawerHeaderTitle
                // action={
                //     <Button
                //         appearance="subtle"
                //         aria-label="Close"
                //         icon={<DismissRegular />}
                //         onClick={toggleDrawer}
                //     />
                // }
                >
                    Navigation
                </DrawerHeaderTitle>
                <div className={styles.drawerContent}>
                    {/* Add navigation links or components here */}
                    <Accordion
                        openItems={openItems}
                        onToggle={handleAccordionToggle}
                        collapsible
                    >
                        <AccordionItem value="home" onClick={() => navigate('/')}>
                            <AccordionHeader icon={<BuildingRegular />}>Home</AccordionHeader>
                        </AccordionItem>
                        <AccordionItem value="reporting">
                            <AccordionHeader icon={<ChartMultipleRegular />}>Reporting</AccordionHeader>
                            <AccordionPanel>
                                <Accordion collapsible>
                                    <AccordionItem disabled={!checkModule('Reporting_Intune')} value="intuneDashboards" onClick={() => navigate('/intunedashboards')}>
                                        <AccordionHeader>Intune Dashboards</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('Reporting_EntraID')} value="entraIDDashboards" onClick={() => navigate('/')}>
                                        <AccordionHeader>Entra ID Dashboards</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('Reporting_MDfE')} value="securityDashboards" onClick={() => navigate('/')}>
                                        <AccordionHeader>Security Dashboards</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkReportingLicense()} value="customDashboards" onClick={() => navigate('/customdashboards')}>
                                        <AccordionHeader>Custom Dashboards</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={isCreateDashDisabled} value="createDashboards" onClick={() => navigate('/createdash')}>
                                        <AccordionHeader>Create Dashboards</AccordionHeader>
                                    </AccordionItem>
                                    {/* Add other Reporting sub-items here */}
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem value="remediationActions">
                            <AccordionHeader icon={<ArrowClockwiseDashesRegular />}>Remediate</AccordionHeader>
                            <AccordionPanel>
                                <Accordion collapsible>
                                    <AccordionItem disabled={!checkModule('Intune_Remediations')} value="intuneRemediationsManage" onClick={() => navigate('/')}>
                                        <AccordionHeader>Manage Remediations</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('Intune_Remediations')} value="intuneRemediationsDeploy" onClick={() => navigate('/')}>
                                        <AccordionHeader>Deploy Remediations</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('Intune_Remediations')} value="intuneRemediationsReporting" onClick={() => navigate('/')}>
                                        <AccordionHeader>Reporting</AccordionHeader>
                                    </AccordionItem>
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem value="m365LifeCycle">
                            <AccordionHeader icon={<ArrowSyncCheckmarkRegular />}>M365 LifeCycle Management</AccordionHeader>
                            <AccordionPanel>
                                <Accordion collapsible>
                                    <AccordionItem disabled={!checkModule('M365LCM_Intune')} value="intuneDR" onClick={() => navigate('/')}>
                                        <AccordionHeader>Intune Backup & Restore</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('M365LCM_CA')} value="conditionalAccessDR" onClick={() => navigate('/')}>
                                        <AccordionHeader>Conditional Access Backup & Restore</AccordionHeader>
                                    </AccordionItem>
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem value="avdManagement">
                            <AccordionHeader icon={<ViewDesktopRegular />}>Azure Virtual Desktop</AccordionHeader>
                            <AccordionPanel>
                                <Accordion collapsible>
                                    <AccordionItem disabled={!checkModule('AVD_Base')} value="avdDeployment" onClick={() => navigate('/')}>
                                        <AccordionHeader>Deployment</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('AVD_Base')} value="avdScaling" onClick={() => navigate('/')}>
                                        <AccordionHeader>Scaling Management</AccordionHeader>
                                    </AccordionItem>
                                    <AccordionItem disabled={!checkModule('AVD_Base')} value="avdReporting" onClick={() => navigate('/')}>
                                        <AccordionHeader>Reporting</AccordionHeader>
                                    </AccordionItem>
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem disabled={!checkAdmin()} value="adminSettings" onClick={() => navigate('/admin')}>
                            <AccordionHeader icon={<SettingsRegular />}>Admin Settings</AccordionHeader>
                        </AccordionItem>
                    </Accordion>
                </div>
            </Drawer>
        </>
    );
}

export default SideNav;