import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    mergeClasses,
    tokens,
    Button,
    Menu,
    MenuItem,
    MenuItemProps,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tab,
    TabList,
    useIsOverflowItemVisible,
    useOverflowMenu,
    Overflow,
    OverflowItem,
    Title1,
} from "@fluentui/react-components";
import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import {
    MoreHorizontalRegular,
    MoreHorizontalFilled,
    bundleIcon,
    Dismiss24Regular,
} from "@fluentui/react-icons";
import { useMsal } from '@azure/msal-react';

import deleteIconDarkMode from './Assets/fluentdeletewhite48.png';
import deleteIconLightMode from './Assets/fluentdeleteblack48.png';


import { appTheme } from './Helpers/customRevealTheme';
import { lightPowerONTheme, darkPowerONTheme, brandPowerON } from './Helpers/fluentPowerONTheme';
import { useSignInState } from './Components/signInState';
import LoadingPage from './Components/LoadingPage';
import useFetch from './Hooks/useFetchAPI';

declare let $: any;

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

type OverflowMenuItemProps = {
    tab: DashboardDataResult;
    onClick: MenuItemProps["onClick"];
};

type OverflowMenuProps = {
    onTabSelect?: (tabname: string) => void;
};

const useStyles = makeStyles({
    menu: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    menuButton: {
        alignSelf: "center",
    },
    horizontal: {
        height: "fit-content",
        minWidth: "150px",
        resize: "horizontal",
        width: "600px",
    },
});

const CustomDashboards = (props: any) => {
    ($.ig.RevealSdkSettings as any).setBaseUrl(process.env.REACT_APP_REVEAL_CUSTOM_URL);

    const { inProgress, accounts, instance } = useMsal();
    const styles = useStyles();

    const { signInState } = useSignInState();
    const [dashboardId, setDashboardId] = useState<string | null>(null);
    const [selectedTabId, setSelectedTabId] = React.useState<string>("");
    const [dashboards, setDashboards] = useState<DashboardDataResult[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dashboardSelectorCallback, setDashboardSelectorCallback] = useState<Function | null>(null);

    const { fetchAPI: fetchDashboards, apiData: dashboardData, isLoading: isLoadingDashboards, serverError: serverErrorDashboards } = useFetch({ scope: 'AppUser' })
    const { fetchAPI: checkDashboards, apiData: checkDashboardData, isLoading: isCheckingDashboards, serverError: serverErrorCheckDashboards } = useFetch({ scope: 'AppUser' })
    const { fetchAPI: deleteDashboards, apiData: deleteDashboardData, isLoading: isDeletingDashboards, serverError: serverErrorDeleteDashboards } = useFetch({ scope: 'AdminUser' })

    const fetchDashList = async () => {
        await fetchDashboards({ url: `${process.env.REACT_APP_REVEAL_DATA_URL}customdashboards`, method: 'GET' });
    };

    const checkDashList = async () => {
        await checkDashboards({ url: `${process.env.REACT_APP_REVEAL_DATA_URL}customdashboards`, method: 'GET' });
    };

    const deleteDashboard = async (dashboardId: string) => {
        await deleteDashboards({ url: `${process.env.REACT_APP_REVEAL_DATA_URL}deletedashboard/${dashboardId}`, method: 'DELETE' });
    };

    const checkAccessTokenExpiry = async (accessToken: string) => {
        // Split the token into parts
        const payload = accessToken.split('.')[1];
        // Base64 decode the payload
        const decodedPayload = atob(payload);
        // Parse the decoded payload to get an object
        const payloadObj = JSON.parse(decodedPayload);
        // Get the expiration time from the payload
        const exp = payloadObj.exp;
        return exp;
      }
    
      const revealHeaders = async () => {
        console.log('[Reveal Headers] Getting token...');
        const silentResult = await instance.acquireTokenSilent({
          scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/AppUser`],
          account: accounts[0],
          forceRefresh: true
        });
    
        const expiryTime = await checkAccessTokenExpiry(silentResult.accessToken);
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiry = expiryTime - currentTime;
        // Schedule the next refresh 4 minutes before expiry just as an example
        const refreshTimeout = (timeUntilExpiry - 240) * 1000;
        console.log('Time until expiry:', timeUntilExpiry, 'Refresh in:', refreshTimeout);
        setTimeout(revealHeaders, refreshTimeout);
    
        ($.ig.RevealSdkSettings as any).setAdditionalHeadersProvider((url: string) => {
          const headers: { [key: string]: string | undefined } = {};
          headers['x-customertid'] = signInState.customerInfo.find((customer: { CustomerID: number; }) => customer.CustomerID === props.currentCustomerID)?.TenantID
          headers["Authorization"] = `Bearer ${silentResult.accessToken}`
          return headers
        });
        $.ig.RevealSdkSettings;
      };

    useEffect(() => {
        $.ig.RevealSdkSettings.theme = appTheme(signInState.userInfo[0]?.ThemePreference);
        if (signInState.userInfo[0]?.ThemePreference === 'light') {
            $.ig.RevealSdkSettings.theme.isDark = false;
        }
        else {
            $.ig.RevealSdkSettings.theme.isDark = true;
        }
        darkPowerONTheme.colorBrandForeground1 = brandPowerON[110]; // use brand[110] instead of brand[100]
        darkPowerONTheme.colorBrandForeground2 = brandPowerON[120]; // use brand[120] instead of brand[110]
    }, [signInState.userInfo[0]?.ThemePreference]);

    useEffect(() => {
        if (inProgress === 'none') {
            revealHeaders()
        }
    }, [inProgress, props.currentCustomerID]);

    useEffect(() => {
        setDashboards([])
        setDashboardId(null)
        fetchDashList()
            .catch((error) => {
                console.error('Error fetching dashboards', error);
                setDashboards([]);
            });
    }, [props.currentCustomerID, isDeletingDashboards]);

    useEffect(() => {
        if (!isDeletingDashboards && !isLoadingDashboards && !serverErrorDashboards && dashboardData && dashboardId) {
            console.log('CustomDashboards: load dashboard', dashboardId);
            $.ig.RVDashboard.loadDashboard(dashboardId).then((dashboard: any) => {
                var revealView = new $.ig.RevealView('#revealView');

                revealView.onDataSourcesRequested = (callback: any) => {
                    revealHeaders();
                    
                    // Intune Full Database
                    let intuneDataSource = new $.ig.RVAzureSqlDataSource();
                    intuneDataSource.id = 'EntraIntune';
                    intuneDataSource.title = 'Entra and Intune Data Source';
                    intuneDataSource.subtitle = 'Entra and Intune Data';

                    // Intune Summary Tables Only
                    let intuneSummariesDataSource = new $.ig.RVAzureSqlDataSource();
                    intuneSummariesDataSource.id = 'IntuneSummaries';
                    intuneSummariesDataSource.title = 'Intune Summary Tables Data Source';
                    intuneSummariesDataSource.subtitle = 'Intune Data';

                    // Intune Summary Tables Only
                    let commonDataSource = new $.ig.RVAzureSqlDataSource();
                    commonDataSource.id = 'CommonData';
                    commonDataSource.title = 'Common Data Source';
                    commonDataSource.subtitle = 'Common Data';

                    // Intune Curated Items
                    let dataSourceItem1 = new $.ig.RVAzureSqlDataSourceItem(intuneDataSource);
                    dataSourceItem1.id = 'DeviceInfo';
                    dataSourceItem1.title = 'Common Device Information';
                    dataSourceItem1.subtitle = 'Information about devices in Entra and Intune';

                    let dataSourceItem2 = new $.ig.RVAzureSqlDataSourceItem(intuneDataSource);
                    dataSourceItem2.id = 'WindowsReleaseInfo';
                    dataSourceItem2.title = 'Windows Update Information';
                    dataSourceItem2.subtitle = 'Information about Windows Update state for devices';

                    callback(new $.ig.RevealDataSources([intuneDataSource, commonDataSource, intuneSummariesDataSource], [dataSourceItem1, dataSourceItem2], false));
                };

                revealView.dashboard = dashboard;
                if (signInState.userFeatures.find((feature: { FeatureID: number; CustomerID: number; Enabled: boolean; }) =>
                    feature.FeatureID === 3 &&
                    feature.CustomerID === signInState.currentCustomerID &&
                    feature.Enabled === true) !== undefined) {
                    revealView.canEdit = true;
                    revealView.canSaveAs = true;

                    revealView.onMenuOpening = function (visualization: any, args: any) {

                        if (args.menuLocation === $.ig.RVMenuLocation.Dashboard) {
                            //adding a new menu item

                            console.log('Theme is Darkmode?:', $.ig.RevealSdkSettings.theme.isDark)
                            if ($.ig.RevealSdkSettings.theme.isDark) {
                                const menuItem = new $.ig.RVMenuItem("Delete Dashboard", new $.ig.RVImage(deleteIconDarkMode, "Icon"), () => {
                                    deleteDashboard(dashboardId);
                                })
                                args.menuItems.push(menuItem);
                            }
                            else {
                                const menuItem = new $.ig.RVMenuItem("Delete Dashboard", new $.ig.RVImage(deleteIconLightMode, "Icon"), () => {
                                    deleteDashboard(dashboardId);
                                })
                                args.menuItems.push(menuItem);
                            }
                        }

                    };

                }
                else {
                    revealView.canEdit = false;
                    revealView.canSaveAs = false;
                }

                revealView.onDashboardSelectorRequested = (args: any) => {
                    setDashboardSelectorCallback(() => args.callback);
                    setIsDialogOpen(true)
                }

                revealView.onLinkedDashboardProviderAsync = (dashboardId: string) => {
                    return $.ig.RVDashboard.loadDashboard(dashboardId);
                };

                revealView.onSave = (rv: any, args: any) => {
                    console.log('RV Args:', args);
                    if (args.saveAs) {
                        const newName = prompt('Enter a new name for the dashboard:', args.name);
                        console.log('Save As:', args.saveAs);
                        args.dashboardId = args.name = newName;

                        // if (args.name.contains(' ')) {
                        //     //Need to find a better way to handle spaces in the name rather than stripping them out
                        //     console.log('Name contains spaces:', args.name);
                        //     args.dashboardId = args.name.replace(/\s/g, '');
                        // }

                        checkDashList()
                            .then(() => {
                                if (Array.isArray(checkDashboardData) && checkDashboardData.some((dashboard: any) => dashboard.name === args.dashboardId)) {
                                    alert('A dashboard with that name already exists. Please choose a different name.');
                                    args.cancel = true;
                                }
                                else {
                                    args.saveFinished();
                                }
                            });
                    }
                    else if (args.name === 'New Dashboard') {
                        alert('You must name the dashboard other then "New Dashboard" before saving.');
                        args.cancel = true;
                    }
                    else {
                        args.saveFinished();
                    }
                }
            })
                .catch((error: any) => {
                    console.error('Error loading dashboard', error);
                    setDashboardId(null);
                });
        }
    }, [dashboardData, serverErrorDashboards, isLoadingDashboards, dashboardId, isDeletingDashboards]);

    useEffect(() => {
        console.log('CustomDashboards: dashboards', dashboardData);
        setDashboards(dashboardData as unknown as DashboardDataResult[]);
    }, [dashboardData]);



    const onTabSelect = (tabname: string) => {
        setSelectedTabId(tabname);
        setDashboardId(tabname || null);
    };

    const OverflowMenuItem = (props: OverflowMenuItemProps) => {
        const { tab, onClick } = props;
        const isVisible = useIsOverflowItemVisible(tab.name);

        if (isVisible) {
            return null;
        }

        return (
            <MenuItem key={tab.name} onClick={onClick}>
                <div>{tab.name}</div>
            </MenuItem>
        );
    };

    const OverflowMenu = (props: OverflowMenuProps) => {
        const { onTabSelect } = props;
        const { ref, isOverflowing, overflowCount } =
            useOverflowMenu<HTMLButtonElement>();

        const onItemClick = (tabname: string) => {
            onTabSelect?.(tabname);
        };

        if (!isOverflowing) {
            return null;
        }

        return (
            <Menu hasIcons>
                <MenuTrigger disableButtonEnhancement>
                    <Button
                        appearance="transparent"
                        className={styles.menuButton}
                        ref={ref}
                        icon={<MoreHorizontal />}
                        aria-label={`${overflowCount} more tabs`}
                        role="tab"
                    />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList className={styles.menu}>
                        {dashboards.map((tab) => (
                            <OverflowMenuItem
                                key={tab.name}
                                tab={tab}
                                onClick={() => onItemClick(tab.name)}
                            />
                        ))}
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    };

    return (
        <div className='Dashboard' style={{ padding: '10px' }}>
            {(isLoadingDashboards || isDeletingDashboards) && <LoadingPage message="Loading Dashboards..." />}
            {!isLoadingDashboards &&
                <>
                    <Dialog modalType="alert"
                        open={isDialogOpen}
                        onOpenChange={(event, data) => {
                            setIsDialogOpen(data.open);
                        }}>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<Dismiss24Regular />}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    Choose a dashboard to link
                                </DialogTitle>
                                <DialogContent>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                        {Array.isArray(dashboardData) ? dashboardData.map((item: { name: string }) => (
                                            <div style={{ flex: '1 0 170px', maxWidth: '170px', margin: '10px' }}>
                                                <Button key={item.name} onClick={() => {
                                                    setIsDialogOpen(false);
                                                    if (dashboardSelectorCallback) {
                                                        dashboardSelectorCallback(item.name);
                                                    }
                                                }} style={{
                                                    maxWidth: '170px',
                                                    minWidth: '170px',
                                                    height: '3em',
                                                }}>
                                                    {item.name}
                                                </Button>
                                            </div>
                                        )) : null}
                                    </div>
                                </DialogContent>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                    {Array.isArray(dashboards) && dashboards.length > 0 ? (
                        <>
                            <div className={mergeClasses(styles.horizontal)}>
                                <Overflow minimumVisible={2}>
                                    <TabList
                                        selectedValue={selectedTabId}
                                        onTabSelect={(_, d) => onTabSelect(d.value as string)}
                                    >
                                        {dashboards.map((tab) => {
                                            return (
                                                <OverflowItem
                                                    key={tab.name}
                                                    id={tab.name}
                                                    priority={tab.name === selectedTabId ? 2 : 1}
                                                >
                                                    <Tab value={tab.name}>
                                                        {tab.name}
                                                    </Tab>
                                                </OverflowItem>
                                            );
                                        })}
                                        <OverflowMenu onTabSelect={onTabSelect} />
                                    </TabList>
                                </Overflow>
                            </div>
                            <div id='revealView' style={{ height: 'calc(100vh - 130px)', width: '100%' }}></div>
                        </>
                    ) : (
                        <Title1>Create a new custom dashboard to get started here</Title1>
                    )}
                </>
            }
        </div>
    );

};

export default CustomDashboards;