declare let $: any;
// const dashboardURL = `${process.env.REACT_APP_REVEAL_DATA_URL}dashboards`;
($.ig.RevealSdkSettings as any).setBaseUrl(process.env.REACT_APP_REVEAL_BASE_URL);

export const appTheme = (themePreference: string): any => {

    if (themePreference === 'dark') {
        $.ig.RevealSdkSettings.theme = new $.ig.MountainDarkTheme();
        $.ig.RevealSdkSettings.theme.isDark = true;
    } else {
        $.ig.RevealSdkSettings.theme = new $.ig.MountainLightTheme();
        $.ig.RevealSdkSettings.theme.isDark = false;
    }
    var theme = $.ig.RevealSdkSettings.theme.clone();  
    //theme.chartColors = ["rgb(76, 45, 129)","rgb(29, 79, 160)",  "rgb(18, 133, 199)"]
    theme.chartColors = ["rgb(23, 104, 238)","rgb(135, 206, 250)",  "rgb(175, 238, 238)"]
    theme.regularFont = "Segoe UI";
    theme.mediumFont = "Segoe UI";
    theme.boldFont = "Segoe UI";
    return theme;
}


/*
PowerON Colours
Purple
HEX: 4c2d81
RGB: 76, 45, 129

Dark blue
HEX: 1d4fa0
RGB: 29, 79, 160

Pink (might want to tone it down slightly)
HEX: e7138b
RGB: 231, 19, 139

Mid blue
HEX: 1285c7
RGB: 18, 133, 199
*/
