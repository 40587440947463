import { useEffect, useRef, useState } from 'react';
import { Text, Button, Card, Subtitle1, Switch, } from '@fluentui/react-components';
import { CheckmarkCircleRegular, DismissRegular, ErrorCircleRegular, QuestionRegular } from "@fluentui/react-icons";
import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
    MessageBarIntent,
    Link,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import { PublicClientApplication, InteractionRequiredAuthError, AuthError, AuthenticationResult } from "@azure/msal-browser";

import { msalConfigGraph, loginRequestGraph } from '../authConfigGraph';
import { useSignInState } from '../signInState';
import authErrorHandling from '../authErrorHandling';
import useFetch from '../../Hooks/useFetchAPI';
import { FeatureSkeleton } from '../LoadingSkeletons';
import ModuleConfigTable from './ModuleConfigTable';

const intents: MessageBarIntent[] = ["info", "warning", "error", "success"];

interface NotificationMessage {
    intent: MessageBarIntent;
    id: number;
    message: string;
};

const useStyles = makeStyles({
    messageBarGroup: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        ...shorthands.overflow("auto"),
    },
});


const ModuleWUfBReporting = () => {
    const styles = useStyles();
    const [authError, setauthError] = useState<string>('');
    const { signInState } = useSignInState();
    const featureName = 'IntuneReporting';
    const counterRef = useRef(0);

    const [featureConfigData, setFeatureConfigData] = useState<WUfBReportingFeatureConfigData[] | null>(null);
    const [wUfBReportingConfigItems, setWUfBReportingConfigItems] = useState<ModuleConfigTableProps[]>([]);
    const { fetchAPI: fetchConfig, apiData, isLoading, serverError } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: updateDatabase, apiData: submitData, isLoading: isLoadingSubmit, serverError: serverErrorSubmit } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: testConfig, apiData: testConfigData, isLoading: isLoadingTestConfig, serverError: serverErrorTestConfig } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: enableDataSync, apiData: enableDataSyncData, isLoading: isLoadingEnableDataSync, serverError: serverErrorEnableDataSync } = useFetch({ scope: 'AdminUser' })

    const [messages, setMessages] = useState<NotificationMessage[]>([]);

    const addMessage = (message: string, intent: MessageBarIntent) => {
        const newMessage = { intent, id: counterRef.current++, message };
        setMessages((s) => [newMessage, ...s]);
    };

    const dismissMessage = (messageId: number) =>
        setMessages((s) => s.filter((entry) => entry.id !== messageId)
        );


    useEffect(() => {
        const fetchConfigAsync = async () => {
            await fetchConfig({ url: `admin/getfeatureconfig?featureName=${featureName}`, method: 'GET' });
        };

        fetchConfigAsync()
    }, [signInState.currentCustomerID]);

    const msalInstanceGraph = new PublicClientApplication(msalConfigGraph(process.env.REACT_APP_CLIENT_GRAPH_APP_ID!));

    const handleAppRegConsent = async () => {
        await msalInstanceGraph.initialize();
        await msalInstanceGraph.loginPopup(loginRequestGraph)
            .then(response => {
                // Handle the response
                setauthError('');
            })
            .catch(error => {
                // Handle the error
                console.log('[consentAppReg] Error:', error);
                setauthError(authErrorHandling(error));
                addMessage(authErrorHandling(error), 'error');
            });
    };

    const updateConfiguration = async (key: string, value: string | number | boolean) => {
        await updateDatabase({ url: `admin/updatefeatureconfig?featureName=${featureName}&${key}=${value}`, method: 'PUT' });

        // setFeatureConfigData((prev) => {
        //     if (prev && prev.length > 0) {
        //         return [
        //             { ...prev[0], [key]: value },
        //             ...prev.slice(1)
        //         ];
        //     }
        //     return prev;
        // });

        setFeatureConfigData((prev) => {
            if (!prev) return prev; // Return unchanged if prev is falsy
            return prev.map(item => 
                item.hasOwnProperty(key) ? { ...item, [key]: value } : item
            );
        });
        setWUfBReportingConfigItems((prev) => {
            if (!prev) return prev; // Return unchanged if prev is falsy
            return prev.map(item => 
                item.hasOwnProperty(key) ? { ...item, [key]: value } : item
            );
        });
    }

    useEffect(() => {
        testConfig({ url: `admin/testfeatureconfig?featureName=${featureName}`, method: 'GET' });
    }, []);

    const testConfiguration = async () => {
        console.log('[ModuleConfig-WUfB] Testing configuration');
        await testConfig({ url: `admin/testfeatureconfig?featureName=${featureName}`, method: 'GET' });
    }

    useEffect(() => {
        (async () => {
        console.log('[ModuleConfig-WUfB] testResult:', testConfigData);
        if (testConfigData?.result) {
            console.log('[ModuleConfig-WUfB] Configuration test successful');
            updateConfiguration('Status', 'Configured');
            updateConfiguration('StatusMessage', 'Tested Successfully');

            //todo: add more detailed test results for each configuration item (Graph, Log Analytics, AutoPatch, etc.)
            //todo: add customer to the datasync db [admin].[SyncTenants] table
            if (featureConfigData?.[0]?.WUfBWorkspaceID && featureConfigData[0].WUfBWorkspaceID !== '') {
                await enableDataSync({ url: 'admin/enabledatasync', method: 'POST', body: { WUfBWorkspaceID: featureConfigData?.[0]?.WUfBWorkspaceID, } });
            }
            else {
                await enableDataSync({ url: 'admin/enabledatasync', method: 'POST', body: { WUfBWorkspaceID: null, } });
            }
        }
        else {
            console.log('[ModuleConfig-WUfB] Configuration test failed');
            updateConfiguration('Status', 'Not Configured');
            updateConfiguration('StatusMessage', 'Test Failed');
        }
    })();
    }, [testConfigData]);

    useEffect(() => {
        if (!isLoading && !serverError && apiData) {
            console.log('[ModuleConfig-WUfB] apiData', apiData)
            if (apiData && Array.isArray(apiData.result) && apiData.result.length > 0 && (apiData as unknown as { result: WUfBReportingFeatureConfigData[] }).result) {
                let featureConfigDataResult: WUfBReportingFeatureConfigData[] | null = null;
                let wUfBReportingConfigItemsResult: ModuleConfigTableProps[] = [];
                featureConfigDataResult = (apiData as unknown as { result: WUfBReportingFeatureConfigData[] }).result;
                // console.log('featureConfigDataResult: ', featureConfigDataResult);
                setFeatureConfigData(featureConfigDataResult);

                if (Array.isArray(featureConfigDataResult)) {
                    wUfBReportingConfigItemsResult = featureConfigDataResult.flatMap(item => ([
                        { key: 'WUfBWorkspaceID', displayName: 'WUfB Log Analytics Workspace ID', value: item.WUfBWorkspaceID },
                    ]));
                }
                // console.log('IntuneReportingConfigItemsResult:', IntuneReportingConfigItemsResult);
                setWUfBReportingConfigItems(wUfBReportingConfigItemsResult);
            }
            else {
                console.log('[ModuleConfig-WUfB] apiData is empty');
                let featureConfigDataResult: WUfBReportingFeatureConfigData[] = [{
                    AppFeatureID: 1,
                    WUfBEnabled: false,
                    WUfBWorkspaceID: '',
                    SyncSchedule2: '',
                    WUfBStatus: '',
                    WUfBStatusMessage: ''
                }];
                setFeatureConfigData(featureConfigDataResult);
                let wUfBReportingConfigItemsResult: ModuleConfigTableProps[] = [
                    { key: 'WUfBWorkspaceID', displayName: 'WUfB Log Analytics Workspace ID', value: '' },
                ];
                setWUfBReportingConfigItems(wUfBReportingConfigItemsResult);
            }
        }
    }, [isLoading, serverError, apiData]);

    return (
        <div className="WUfBConfig" style={{ margin: '20px' }}>
            {isLoading && !serverError && !apiData &&
                <Card>
                    <Subtitle1>Windows Update for Business Reporting Connector Setup</Subtitle1>
                    <FeatureSkeleton />
                </Card>

            }
            {!isLoading && !serverError && featureConfigData && apiData && wUfBReportingConfigItems &&
                <Card>
                    <Subtitle1>Windows Update for Business Reporting Connector Setup</Subtitle1>
                    <Text>An Entra ID Global Admin or Application Administrator will need to grant consent to the Additionality app to allow access to your data for WUfB reporting.</Text>
                    <Text>Ensure you have manually granted read permissions to the Additionality-GraphSync App (Service Principal) to your Log Analytics Workspace.</Text>
                    <br/>
                    <Switch
                        key={"WUfBEnabled"}
                        // label={"Connector " + (featureConfigData[0].FeatureEnabled ? "enabled" : "disabled")}
                        label="Connector enabled"
                        labelPosition="before"
                        checked={featureConfigData[0].WUfBEnabled}
                        onChange={(e) => updateConfiguration("WUfBEnabled", e.target.checked)}
                    />

                    <Text>{featureConfigData[0]?.WUfBStatus ? `Connector Status: ${featureConfigData[0]?.WUfBStatus}` : null}</Text>
                    <Text>{featureConfigData[0]?.WUfBStatusMessage ? `Connector Status Message: ${featureConfigData[0]?.WUfBStatusMessage}` : null}</Text>

                    
                    < ModuleConfigTable<ModuleConfigTableProps[]> data={wUfBReportingConfigItems} appFeature={featureName} onUpdate={updateConfiguration} />

                    <div style={{ minWidth: '50%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                        < Button style={{ width: '100%' }} onClick={handleAppRegConsent} appearance='primary'> Grant Consent </Button>
                    </div>
                    <div style={{ width: '50%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ width: '100%' }} onClick={testConfiguration}
                            icon={featureConfigData[0].WUfBStatusMessage === 'Tested Successfully'
                                ? <CheckmarkCircleRegular style={{ color: 'green' }} />
                                : <ErrorCircleRegular style={{ color: 'red' }} />}
                        >
                            Test Configuration
                        </Button>
                    </div>
                    {/* <br/> */}
                    {authError &&
                        <MessageBarGroup className={styles.messageBarGroup}>
                            {messages.map(({ intent, id, message }) => (
                                <MessageBar key={`${intent}-${id}`} intent={intent}>
                                    <MessageBarBody>
                                        <MessageBarTitle>Error granting access</MessageBarTitle>
                                        {message}
                                    </MessageBarBody>
                                    <MessageBarActions
                                        containerAction={
                                            <Button
                                                onClick={() => dismissMessage(id)}
                                                aria-label="dismiss"
                                                appearance="transparent"
                                                icon={<DismissRegular />}
                                            />
                                        }
                                    />
                                </MessageBar>
                            ))}
                        </MessageBarGroup>
                    }
                </Card>
            }
            {serverError &&
                // Put in nice pretty error handling component when it's created
                <>Server Error: {serverError}</>
            }

        </div>
    );

};

export default ModuleWUfBReporting;
