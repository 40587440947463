import {
    Skeleton,
    SkeletonItem,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import type { SkeletonProps } from "@fluentui/react-components";

const useStyles = makeStyles({
    invertedWrapper: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    firstRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("10px"),
        gridTemplateColumns: "min-content 68%",
    },
    secondThirdRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("10px"),
        gridTemplateColumns: "min-content 20% 20% 15% 15%",
    },
    shortRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("10px"),
        gridTemplateColumns: "1fr",
    },
});

export const FeatureSkeleton = (props: Partial<SkeletonProps>) => {
    const styles = useStyles();
    return (
        <div className={styles.invertedWrapper}>
            <Skeleton {...props}>
                <div className={styles.firstRow}>
                    <SkeletonItem shape="circle" size={24} />
                    <SkeletonItem shape="rectangle" size={16} />
                </div>
                <div className={styles.secondThirdRow}>
                    <SkeletonItem shape="circle" size={24} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                </div>
                <div className={styles.secondThirdRow}>
                    <SkeletonItem shape="square" size={24} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                </div>
            </Skeleton>
        </div>
    );
};

export const InvitesTable = (props: Partial<SkeletonProps>) => {
    const styles = useStyles();
    return (
        <div className={styles.invertedWrapper}>
            <Skeleton {...props}>
                <div className={styles.firstRow}>
                    <SkeletonItem shape="rectangle" size={16} />
                    <SkeletonItem size={24} />
                </div>
            </Skeleton>
        </div>
    );
};


export const UserInformationSkeleton = (props: Partial<SkeletonProps>) => {
    const styles = useStyles();
    return (
        <div className={styles.invertedWrapper}>
            {/* Simulate the avatar */}
            <Skeleton {...props} aria-busy>

                <div className={styles.firstRow}>
                    <SkeletonItem shape="circle" size={48} />
                    <SkeletonItem shape="rectangle" size={16} />
                </div>

                <div className={styles.secondThirdRow}>
                    {/* Simulate the user's name and email */}
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                </div>

                {/* Simulate the user info fields */}
                <div className={styles.secondThirdRow}>
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                </div>
                <div className={styles.secondThirdRow}>
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                </div>
                <div className={styles.secondThirdRow}>
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                </div>
                <div className={styles.secondThirdRow}>
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                    <SkeletonItem size={24} />
                </div>
                <br />
                {/* Simulate the feature toggles */}
                <div className={styles.secondThirdRow}>
                    <SkeletonItem shape="rectangle" size={16} />
                    <SkeletonItem shape="rectangle" size={16} />
                </div>
                <div className={styles.secondThirdRow}>
                    <SkeletonItem size={8} />
                    <SkeletonItem shape="square" size={16} />
                    <SkeletonItem shape="square" size={16} />
                    <SkeletonItem shape="square" size={16} />
                </div>
                <div className={styles.secondThirdRow}>
                    {/* Simulate the role section */}
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                </div>
                <div className={styles.secondThirdRow}>
                    {/* Simulate the role section */}
                    <SkeletonItem size={8} />
                    <SkeletonItem shape="square" size={16} />
                    <SkeletonItem shape="square" size={16} />
                    <SkeletonItem shape="square" size={16} />
                </div>

            </Skeleton>
        </div>
    );
};

export const ServiceHealth = (props: Partial<SkeletonProps>) => {
    const styles = useStyles();
    return (
        <div className={styles.invertedWrapper}>
            <Skeleton {...props}>
                <div className={styles.shortRow}>
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                    <SkeletonItem size={16} />
                </div>
            </Skeleton>
        </div>
    );
};