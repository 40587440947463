import { useEffect, useState } from "react";
import {
    MessageBar,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
    MessageBarIntent,
    makeStyles,
    shorthands,
    tokens,
    Dialog,
    DialogSurface,
    Button,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogTitle,
    MessageBarActions,
    Accordion,
    AccordionPanel,
    AccordionItem,
    Text,
    AccordionHeader,
    Subtitle2,
    Card,
    Body1Strong,
    DialogTrigger,
    Title3,
} from "@fluentui/react-components";

import useFetch from '../Hooks/useFetchAPI';
import { ServiceHealth } from './LoadingSkeletons';
import { DismissRegular, InfoRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    controlsContainer: {
        display: "flex",
        flexDirection: "row",
    },
    messageBarGroup: {
        // ...shorthands.padding(tokens.spacingHorizontalMNudge),
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("10px"),
        height: "300px",
        // ...shorthands.overflow("auto"),
    },
    serviceHealth: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("10px"),
        height: "300px",
        width: "200px",
    },
});

interface ServiceHealthMsg {
    service: string;
    status: MessageBarIntent;
    id: string;
    issues: [];
};

type ServiceIssuesType = {
    service: string;
    issues: ServiceIssue[];
};

const ServiceHealthHome = () => {
    const styles = useStyles();

    const { fetchAPI: fetchServiceHealth, apiData: serviceHealthData, isLoading: isLoadingServiceHealth, serverError: serverErrorServiceHealth } = useFetch({ scope: 'AppUser' })

    const [serviceHealth, setServiceHealth] = useState<ServiceHealthMsg[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [serviceIssues, setServiceIssues] = useState<ServiceIssuesType>({ service: '', issues: [] });

    const readableDate = (date: Date) => {
        return new Intl.DateTimeFormat('default', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }).format(date);
    };

    const statusDisplayNames: { [key: string]: string } = {
        serviceOperational: 'Service Operational',
        investigating: 'Investigating',
        restoringService: 'Restoring Service',
        verifyingService: 'Verifying Service',
        serviceRestored: 'Service Restored',
        postIncidentReviewPublished: 'Post Incident Review Published',
        serviceDegradation: 'Service Degradation',
        serviceInterruption: 'Service Interruption',
        extendedRecovery: 'Extended Recovery',
        falsePositive: 'False Positive',
        investigationSuspended: 'Investigation Suspended',
        resolved: 'Resolved',
        mitigatedExternal: 'Mitigated (External)',
        mitigated: 'Mitigated',
        resolvedExternal: 'Resolved (External)',
        confirmed: 'Confirmed',
        reported: 'Reported',
        unknownFutureValue: 'Unknown Future Value'
    };

    const fetchMSServiceHealth = async () => {
        await fetchServiceHealth({ url: `${process.env.REACT_APP_API_URL}graph/servicehealth`, method: 'GET' });
    };

    useEffect(() => {
        try {
            fetchMSServiceHealth()
        } catch (error) {
            console.error('Error fetching service health', error);
        };
    }, []);

    useEffect(() => {
        if (serviceHealthData && !isLoadingServiceHealth && !serverErrorServiceHealth) {
            if (Array.isArray(serviceHealthData.serviceHealth)) {
                // replace the status in serviceHealthData with the MessageBarIntent ("success" "warning" "error" "info")
                // https://learn.microsoft.com/en-us/graph/api/resources/servicehealthissue?view=graph-rest-beta#servicehealthstatus-values
                // serviceOperational, investigating, restoringService, verifyingService, serviceRestored, postIncidentReviewPublished, serviceDegradation, serviceInterruption, extendedRecovery, falsePositive, investigationSuspended  -- Reserved for future use: resolved, mitigatedExternal, mitigated, resolvedExternal, confirmed, reported, unknownFutureValue

                const serviceHealthDataWithIntent = serviceHealthData.serviceHealth.map(({ service, status, id, issues }) => {
                    let intent: MessageBarIntent = "info";
                    if (status === "serviceOperational") {
                        intent = "success";
                    } else if (status === "serviceDegradation" || status === "investigating" || status === "verifyingService" || status === "extendedRecovery") {
                        intent = "warning";
                    } else if (status === "serviceInterruption" || status === "restoringService") {
                        intent = "error";
                    }
                    return { service, status: intent, id, issues };
                });
                setServiceHealth(serviceHealthDataWithIntent as ServiceHealthMsg[]);
                console.log(serviceHealthDataWithIntent);
            }
            else {
                //Create an empty array for serviceHealthDataWithIntent as ServiceHealthMsg
                const serviceHealthDataWithIntent: ServiceHealthMsg[] = [];
                setServiceHealth(serviceHealthDataWithIntent as ServiceHealthMsg[]);
            }
        }
    }, [serviceHealthData, isLoadingServiceHealth, serverErrorServiceHealth]);

    return (
        <>
            {showDialog &&
                <>
                    <Dialog modalType="modal"
                        open={showDialog}
                        onOpenChange={(event, data) => {
                            setShowDialog(data.open);
                        }}
                    >
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<DismissRegular />}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    Current Service Issues - {serviceIssues.service}
                                </DialogTitle>
                                <DialogContent>
                                    {serviceIssues &&
                                        <>
                                            <Subtitle2>Open Issues</Subtitle2>
                                            <Accordion>
                                                {serviceIssues.issues.filter(issue => !issue.isResolved).sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()).length > 0 ?
                                                    serviceIssues.issues.filter(issue => !issue.isResolved).sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()).map(({ title, startDateTime, status, classification, impactDescription, id }) => (
                                                        <AccordionItem value={id}>
                                                            <AccordionHeader>{readableDate(new Date(startDateTime)) + ' - ' + title}</AccordionHeader>
                                                            <AccordionPanel>
                                                                <Card style={{ marginTop: '5px', marginBottom: '5px' }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <Body1Strong>Issue ID</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{id}</Text>
                                                                        <Body1Strong>Status</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{statusDisplayNames[status]}</Text>
                                                                        <Body1Strong>Start time</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{readableDate(new Date(startDateTime))}</Text>
                                                                        <Body1Strong>Issue Type</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{classification === 'advisory' ? 'Advisory' : 'Incident'}</Text>
                                                                        <Body1Strong>Impact description</Body1Strong>
                                                                        <Text>{impactDescription}</Text>
                                                                    </div>
                                                                </Card>
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    ))
                                                    : <Text>No Open Issues</Text>
                                                }
                                            </Accordion>
                                            <p />
                                            <Subtitle2>Resolved Issues</Subtitle2>
                                            <Accordion>
                                                {serviceIssues.issues.filter(issue => issue.isResolved).sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()).length > 0 ?
                                                    serviceIssues.issues.filter(issue => issue.isResolved).sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()).map(({ title, startDateTime, endDateTime, status, classification, impactDescription, id }) => (
                                                        <AccordionItem value={id}>
                                                            <AccordionHeader>{title}</AccordionHeader>
                                                            <AccordionPanel>
                                                                <Card style={{ marginTop: '5px', marginBottom: '5px' }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <Body1Strong>Issue ID</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{id}</Text>
                                                                        <Body1Strong>Status</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{statusDisplayNames[status]}</Text>
                                                                        <Body1Strong>Start time</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{readableDate(new Date(startDateTime))}</Text>
                                                                        <Body1Strong>End time</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{readableDate(new Date(endDateTime))}</Text>
                                                                        <Body1Strong>Issue Type</Body1Strong>
                                                                        <Text style={{ marginBottom: '5px' }}>{classification === 'advisory' ? 'Advisory' : 'Incident'}</Text>
                                                                        <Body1Strong>Impact description</Body1Strong>
                                                                        <Text>{impactDescription}</Text>
                                                                    </div>
                                                                </Card>
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    ))
                                                    : <Text>No Resolved Issues</Text>
                                                }
                                            </Accordion>
                                        </>
                                    }
                                </DialogContent>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                </>
            }
            <div className={styles.serviceHealth}>
            <Title3>Service Health</Title3>
                {isLoadingServiceHealth && <ServiceHealth />}
                {!isLoadingServiceHealth && !serverErrorServiceHealth && serviceHealth.length > 0 &&
                    <div className={styles.controlsContainer}>
                        <MessageBarGroup animate={"both"} className={styles.messageBarGroup}>
                            {serviceHealth.map(({ service, status, id, issues }) => (
                                <MessageBar key={id} intent={status}>
                                    <MessageBarBody>
                                        <MessageBarTitle>{service}</MessageBarTitle>
                                    </MessageBarBody>
                                    <MessageBarActions
                                        containerAction={
                                            <Button aria-label="dismiss" appearance="transparent" onClick={() => { setShowDialog(true); setServiceIssues({ service: service, issues: issues }); }} icon={<InfoRegular />} />
                                        }
                                    >
                                    </MessageBarActions>
                                </MessageBar>
                            ))}
                        </MessageBarGroup>
                    </div>
                }
            </div>
        </>
    );
}

export default ServiceHealthHome;