import { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useAuthHeader } from '../Components/getAuthHeader';

interface UpdateDatabaseProps {
    url: string;
    method: "POST" | "PUT" | "DELETE" | "PATCH" | undefined;
    body: Record<string, unknown>;
}
interface UseUpdateDatabaseProps {
    scope: string;
    method: "POST" | "PUT" | "DELETE" | "PATCH" | undefined;
}

const useUpdateDatabase = ({ scope, method }: UseUpdateDatabaseProps) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { inProgress, accounts } = useMsal();

    const [authHeader, setAuthHeader] = useState({});
    const [pendingSubmit, setPendingSubmit] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [apiSubmitData, setApiSubmitData] = useState<Record<string, string | number | boolean | undefined> | null>(null);

    const getAuthHeader = useAuthHeader(scope, method);

    useEffect(() => {
        //Get the auth header
        const fetchAuthHeader = async () => {
            const header = await getAuthHeader();
            setAuthHeader(header);
        };
        if (inProgress === "none" && accounts.length > 0) {
            fetchAuthHeader();
        }
    }, [inProgress, accounts]);

    const updateDatabase = useCallback(async({ url, method, body }: UpdateDatabaseProps) => {
        // Fetch data from the API
        // console.log('[updateDatabase] Updating database body:', JSON.stringify(body));
        setPendingSubmit(true);
        function isRequestInit(obj: any): obj is RequestInit {
            return obj && typeof obj === 'object' && 'method' in obj && 'headers' in obj
        }
        if (isRequestInit(authHeader)) {
            fetch(`${apiUrl}${url}`, {
                ...authHeader,
                method: method,
                body: JSON.stringify(body)
            })
                .then(response => response.json())
                .then(data => {
                    // console.log('[updateDatabase] Returned data:', data);
                    setApiSubmitData(data)
                    setPendingSubmit(false);
                })
                .catch(error => {
                    console.error('[updateDatabase] error:', error)
                    setSubmitError(error)
                    setPendingSubmit(false)
                }
                );

        }
    }, [authHeader, apiUrl]);

    return { updateDatabase, pendingSubmit, submitError, apiSubmitData };
};

export default useUpdateDatabase;