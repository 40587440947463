import { useEffect, useRef, useState } from 'react';
import { Text, Button, Card, Subtitle1, Switch, } from '@fluentui/react-components';
import { CheckmarkCircleRegular, DismissRegular, ErrorCircleRegular, QuestionRegular } from "@fluentui/react-icons";
import {
    MessageBar,
    MessageBarActions,
    MessageBarTitle,
    MessageBarBody,
    MessageBarGroup,
    MessageBarIntent,
    Link,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import { PublicClientApplication, InteractionRequiredAuthError, AuthError, AuthenticationResult } from "@azure/msal-browser";

import { msalConfigGraph, loginRequestGraph } from '../authConfigGraph';
import { useSignInState } from '../signInState';
import authErrorHandling from '../authErrorHandling';
import useFetch from '../../Hooks/useFetchAPI';
import { FeatureSkeleton } from '../LoadingSkeletons';
import ModuleConfigTable from './ModuleConfigTable';

const intents: MessageBarIntent[] = ["info", "warning", "error", "success"];

interface NotificationMessage {
    intent: MessageBarIntent;
    id: number;
    message: string;
};

const useStyles = makeStyles({
    messageBarGroup: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        ...shorthands.overflow("auto"),
    },
});


const ModuleDellWarranty = () => {
    const styles = useStyles();
    const [authError, setauthError] = useState<string>('');
    const { signInState } = useSignInState();
    const featureName = 'IntuneReporting';
    const counterRef = useRef(0);

    const [featureConfigData, setFeatureConfigData] = useState<DellWarrantyFeatureConfigData[] | null>(null);
    const [wUfBReportingConfigItems, setWUfBReportingConfigItems] = useState<ModuleConfigTableProps[]>([]);
    const { fetchAPI: fetchConfig, apiData, isLoading, serverError } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: updateDatabase, apiData: submitData, isLoading: isLoadingSubmit, serverError: serverErrorSubmit } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: testConfig, apiData: testConfigData, isLoading: isLoadingTestConfig, serverError: serverErrorTestConfig } = useFetch({ scope: 'AdminUser' })
    const { fetchAPI: enableDataSync, apiData: enableDataSyncData, isLoading: isLoadingEnableDataSync, serverError: serverErrorEnableDataSync } = useFetch({ scope: 'AdminUser' })

    const [messages, setMessages] = useState<NotificationMessage[]>([]);

    const addMessage = (message: string, intent: MessageBarIntent) => {
        const newMessage = { intent, id: counterRef.current++, message };
        setMessages((s) => [newMessage, ...s]);
    };

    const dismissMessage = (messageId: number) =>
        setMessages((s) => s.filter((entry) => entry.id !== messageId)
        );


    useEffect(() => {
        const fetchConfigAsync = async () => {
            await fetchConfig({ url: `admin/getfeatureconfig?featureName=${featureName}`, method: 'GET' });
        };

        fetchConfigAsync()
    }, [signInState.currentCustomerID]);

    const msalInstanceGraph = new PublicClientApplication(msalConfigGraph(process.env.REACT_APP_CLIENT_GRAPH_APP_ID!));

    const handleAppRegConsent = async () => {
        await msalInstanceGraph.initialize();
        await msalInstanceGraph.loginPopup(loginRequestGraph)
            .then(response => {
                // Handle the response
                setauthError('');
            })
            .catch(error => {
                // Handle the error
                console.log('[consentAppReg] Error:', error);
                setauthError(authErrorHandling(error));
                addMessage(authErrorHandling(error), 'error');
            });
    };

    const updateConfiguration = async (key: string, value: string | number | boolean) => {
        await updateDatabase({ url: `admin/updatefeatureconfig?featureName=${featureName}&${key}=${value}`, method: 'PUT' });

        // setFeatureConfigData((prev) => {
        //     if (prev && prev.length > 0) {
        //         return [
        //             { ...prev[0], [key]: value },
        //             ...prev.slice(1)
        //         ];
        //     }
        //     return prev;
        // });

        setFeatureConfigData((prev) => {
            if (!prev) return prev; // Return unchanged if prev is falsy
            return prev.map(item => 
                item.hasOwnProperty(key) ? { ...item, [key]: value } : item
            );
        });
        setWUfBReportingConfigItems((prev) => {
            if (!prev) return prev; // Return unchanged if prev is falsy
            return prev.map(item => 
                item.hasOwnProperty(key) ? { ...item, [key]: value } : item
            );
        });
    }

    useEffect(() => {
        testConfig({ url: `admin/testfeatureconfig?featureName=${featureName}`, method: 'GET' });
    }, []);

    const testConfiguration = async () => {
        console.log('[ModuleConfig-DellWarranty] Testing configuration');
        await testConfig({ url: `admin/testfeatureconfig?featureName=${featureName}`, method: 'GET' });
    }

    useEffect(() => {
        (async () => {
        console.log('[ModuleConfig-DellWarranty] testResult:', testConfigData);
        if (testConfigData?.result) {
            console.log('[ModuleConfig-DellWarranty] Configuration test successful');
            updateConfiguration('Status', 'Configured');
            updateConfiguration('StatusMessage', 'Tested Successfully');

            //todo: add more detailed test results for each configuration item (Graph, Log Analytics, AutoPatch, etc.)
            //todo: add customer to the datasync db [admin].[SyncTenants] table
            if (featureConfigData?.[0]?.DellWarrantryClientID && featureConfigData[0].DellWarrantryClientID !== '') {
                await enableDataSync({ url: 'admin/enabledatasync', method: 'POST', body: { DellWarrantryClientID: featureConfigData?.[0]?.DellWarrantryClientID, } });
            }
            else {
                await enableDataSync({ url: 'admin/enabledatasync', method: 'POST', body: { DellWarrantryClientID: null, } });
            }
        }
        else {
            console.log('[ModuleConfig-DellWarranty] Configuration test failed');
            updateConfiguration('Status', 'Not Configured');
            updateConfiguration('StatusMessage', 'Test Failed');
        }
    })();
    }, [testConfigData]);

    useEffect(() => {
        if (!isLoading && !serverError && apiData) {
            console.log('[ModuleConfig-DellWarranty] apiData', apiData)
            if (apiData && Array.isArray(apiData.result) && apiData.result.length > 0 && (apiData as unknown as { result: DellWarrantyFeatureConfigData[] }).result) {
                let featureConfigDataResult: DellWarrantyFeatureConfigData[] | null = null;
                let dellWarrantyConfigItemsResult: ModuleConfigTableProps[] = [];
                featureConfigDataResult = (apiData as unknown as { result: DellWarrantyFeatureConfigData[] }).result;
                // console.log('featureConfigDataResult: ', featureConfigDataResult);
                setFeatureConfigData(featureConfigDataResult);

                if (Array.isArray(featureConfigDataResult)) {
                    dellWarrantyConfigItemsResult = featureConfigDataResult.flatMap(item => ([
                        { key: 'DellWarrantryClientID', displayName: 'Dell Warranty API Client ID', value: item.DellWarrantryClientID },
                        { key: 'DellWarrantyClientSecret', displayName: 'Dell Warranty API Client Secret', value: item.DellWarrantyClientSecret },
                    ]));
                }
                // console.log('IntuneReportingConfigItemsResult:', IntuneReportingConfigItemsResult);
                setWUfBReportingConfigItems(dellWarrantyConfigItemsResult);
            }
            else {
                console.log('[ModuleConfig-DellWarranty] apiData is empty');
                let featureConfigDataResult: DellWarrantyFeatureConfigData[] = [{
                    AppFeatureID: 1,
                    DellWarrantyEnabled: false,
                    DellWarrantryClientID: '',
                    DellWarrantyClientSecret: '',
                    SyncSchedule3: '',
                    DellStatus: '',
                    DellStatusMessage: ''
                }];
                setFeatureConfigData(featureConfigDataResult);
                let dellWarrantyConfigItemsResult: ModuleConfigTableProps[] = [
                    { key: 'DellWarrantryClientID', displayName: 'Dell Warranty API Client ID', value: '' },
                    { key: 'DellWarrantyClientSecret', displayName: 'Dell Warranty API Client Secret', value: '' },
                ];
                setWUfBReportingConfigItems(dellWarrantyConfigItemsResult);
            }
        }
    }, [isLoading, serverError, apiData]);

    return (
        <div className="DellWarrantyConfig" style={{ margin: '20px' }}>
            {isLoading && !serverError && !apiData &&
                <Card>
                    <Subtitle1>Dell Warranty Connector Setup</Subtitle1>
                    <FeatureSkeleton />
                </Card>

            }
            {!isLoading && !serverError && featureConfigData && apiData && wUfBReportingConfigItems &&
                <Card>
                    <Subtitle1>Dell Warranty Connector Setup</Subtitle1>
                    <Text>Contact your Dell Account Manager or visit DellTechDirect to sign up for your Warranty API Keys.</Text>
                    <br/>
                    <Switch
                        key={"DellWarrantyEnabled"}
                        // label={"Connector " + (featureConfigData[0].FeatureEnabled ? "enabled" : "disabled")}
                        label="Connector enabled"
                        labelPosition="before"
                        checked={featureConfigData[0].DellWarrantyEnabled}
                        onChange={(e) => updateConfiguration("DellWarrantyEnabled", e.target.checked)}
                    />

                    <Text>{featureConfigData[0]?.DellStatus ? `Connector Status: ${featureConfigData[0]?.DellStatus}` : null}</Text>
                    <Text>{featureConfigData[0]?.DellStatusMessage ? `Connector Status Message: ${featureConfigData[0]?.DellStatusMessage}` : null}</Text>

                    
                    < ModuleConfigTable<ModuleConfigTableProps[]> data={wUfBReportingConfigItems} appFeature={featureName} onUpdate={updateConfiguration} />

                    <div style={{ width: '50%', margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ width: '100%' }} onClick={testConfiguration}
                            icon={featureConfigData[0].DellStatusMessage === 'Tested Successfully'
                                ? <CheckmarkCircleRegular style={{ color: 'green' }} />
                                : <ErrorCircleRegular style={{ color: 'red' }} />}
                        >
                            Test Configuration
                        </Button>
                    </div>
                    {/* <br/> */}
                    {authError &&
                        <MessageBarGroup className={styles.messageBarGroup}>
                            {messages.map(({ intent, id, message }) => (
                                <MessageBar key={`${intent}-${id}`} intent={intent}>
                                    <MessageBarBody>
                                        <MessageBarTitle>Error granting access</MessageBarTitle>
                                        {message}
                                    </MessageBarBody>
                                    <MessageBarActions
                                        containerAction={
                                            <Button
                                                onClick={() => dismissMessage(id)}
                                                aria-label="dismiss"
                                                appearance="transparent"
                                                icon={<DismissRegular />}
                                            />
                                        }
                                    />
                                </MessageBar>
                            ))}
                        </MessageBarGroup>
                    }
                </Card>
            }
            {serverError &&
                // Put in nice pretty error handling component when it's created
                <>Server Error: {serverError}</>
            }

        </div>
    );

};

export default ModuleDellWarranty;
