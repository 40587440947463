import { useMsal } from '@azure/msal-react';


export const useHandleLogout = () => {
    const { instance } = useMsal();

    const handleLogout = async () => {
        instance.logoutRedirect().then((logoutResponse) => {
            console.log('User logged out:', logoutResponse);
        }).catch((error) => {
            console.error('Logout failed:', error);
        });
    };

    return { handleLogout }
};
