import disableConsoleLogging from './Helpers/disableConsoleLogging';

disableConsoleLogging();

import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './Components/authConfig';

import { SignInStateProvider } from './Components/signInState';
import App from './App';

import './index.css';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <SignInStateProvider>
          <App />
        </SignInStateProvider>
      </MsalProvider>
  </React.StrictMode>
);

