import { useMsal } from '@azure/msal-react';
import { useSignInState } from '../Components/signInState';

function useAuthHeader(scope: string, method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | undefined): () => Promise<RequestInit> {
  const { accounts, instance } = useMsal();
  const { signInState } = useSignInState();
  const tokenAccount = instance.getAllAccounts()[0];
  
  const getAuthHeader = async () => {
    let currentCustomerTid = signInState.customerInfo.find((customer: { CustomerID: number; }) => customer.CustomerID === signInState.currentCustomerID)?.TenantID
    console.log('currentCustomerTid', currentCustomerTid);
    try {
      const silentResult = await instance.acquireTokenSilent({
        scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/${scope}`],
        account: tokenAccount, //accounts[0],
      });

      const headers: Record<string, string> = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${silentResult.accessToken}`,
      };
  
      if (currentCustomerTid) {
        headers["x-customertid"] = currentCustomerTid;
      }
  
      return {
        ...(method ? { method } : {}),
        headers,
      };


    } catch (error) {
      if ((error as any).name === "InteractionRequiredAuthError") {
        const interactiveResult = await instance.acquireTokenPopup({
          scopes: [`api://${process.env.REACT_APP_CLIENT_APP_ID}/${scope}`],
          account: accounts[0],
        });

        const headers: Record<string, string> = {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${interactiveResult.accessToken}`,
        };
    
        if (currentCustomerTid) {
          headers["x-customertid"] = currentCustomerTid;
        }
    
        return {
          ...(method ? { method } : {}),
          headers,
        };

      } else {
        throw error;
      }
    }
  };

  return getAuthHeader;
}

export { useAuthHeader };