import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {
    makeStyles,
    shorthands,
    Label,
    Button,
    Select,
    Input,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    Card,
    Title1,
    Subtitle1,
    Text
} from "@fluentui/react-components";
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
} from "@fluentui/react-components";
import type { PositioningImperativeRef } from "@fluentui/react-components";
import { PersonAccountsRegular, SignOutRegular } from "@fluentui/react-icons";

import countries from 'i18n-iso-countries';

import useFetch from '../Hooks/useFetchAPI';
import { useHandleLogout } from './HandleLogout';
import { loginRequest } from './authConfig';
import authErrorHandling from './authErrorHandling';

const useStyles = makeStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        ...shorthands.gap('1rem'),
        ...shorthands.padding('2rem'),
        boxSizing: 'border-box',
        maxWidth: '800px',
        minWidth: '370px',
        ...shorthands.margin('auto'),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    // other styles...
});

interface FormFields {
    tenantId: string;
    tenantName: string;
    tenantType: string;
    defaultUsageLocation: string;
    companyDisplayName: string;
    localAccountId: string;
    userName: string;
    contactName: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    phoneNumber: string;
    address: string;
    country: string;
    isActive: boolean;
    [key: string]: string | boolean;
}




const RegisterNewCustomer = () => {
    // const apiUrl = process.env.REACT_APP_API_URL;
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const positioningRef = React.useRef<PositioningImperativeRef>(null);

    const { accounts, instance, inProgress } = useMsal();
    const styles = useStyles();
    const navigate = useNavigate();
    const { handleLogout } = useHandleLogout();
    const [authError, setAuthError] = useState<string>('');
    const [showDialog, setShowDialog] = useState(false);
    const { fetchAPI: registerCustomer, apiData: registerCustomerData, isLoading: isLoading, serverError: serverError } = useFetch({ scope: 'AppUser' })

    const [formData, setFormData] = useState<FormFields>({
        tenantId: '',
        tenantName: '',
        tenantType: '',
        defaultUsageLocation: '',
        companyDisplayName: '',
        localAccountId: '',
        userName: '',
        contactName: '',
        firstName: '',
        lastName: '',
        userEmail: '',
        phoneNumber: '',
        address: '',
        country: '',
        isActive: true
    });

    const [showPopover, setShowPopover] = useState(false);
    const [popOverMessage, setpopOverMessage] = useState('');
    const registerCustomerAsync = async () => {
        await registerCustomer({ url: 'invite/registernewcustomer', method: 'POST', body: formData });
    };

    useEffect(() => {
        // Register the locale for i18n-iso-countries
        // English locale is usually bundled with the library. For other locales, you might need to register them separately.
        countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    }, []);

    const countryList = countries.getNames("en", { select: "official" });
    const countryOptions = Object.entries(countryList);

    useEffect(() => {

        if (accounts[0] && inProgress === 'none') {
            const fetchProfileData = async () => {
                const silentResult = await instance.acquireTokenSilent({
                    scopes: ["User.Read"],
                    account: accounts[0]
                });

                const profileResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
                    headers: {
                        Authorization: `Bearer ${silentResult.accessToken}`
                    }
                });

                const orgResponse = await fetch('https://graph.microsoft.com/v1.0/organization', {
                    headers: {
                        Authorization: `Bearer ${silentResult.accessToken}`
                    }
                });

                if (orgResponse.ok && profileResponse.ok) {
                    const orgData = await orgResponse.json();
                    console.log('Org data:', orgData);

                    const profileData = await profileResponse.json();
                    console.log('Profile data:', profileData);

                    const combinedData = {
                        ...orgData,
                        ...profileData
                    };
                    console.log('Combined data:', combinedData);
                    return combinedData;
                } else {
                    console.error('Failed to fetch data:', orgResponse.status, profileResponse.status);
                    return null;
                }
            };

            fetchProfileData()
                .then((combinedData) => {
                    if (combinedData) {
                        setFormData({
                            ...formData,
                            tenantId: accounts[0].tenantId,
                            tenantName: combinedData.value[0].displayName || '',
                            tenantType: combinedData.value[0].tenantType || '',
                            defaultUsageLocation: combinedData.value[0].defaultUsageLocation || '',
                            localAccountId: accounts[0].localAccountId,
                            companyDisplayName: combinedData.value[0].displayName || '',
                            userName: accounts[0].username,
                            userEmail: combinedData.mail || '',
                            firstName: combinedData.givenName || '',
                            lastName: combinedData.surname || '',
                            contactName: combinedData.displayName || '',
                            phoneNumber: combinedData.businessPhones[0] || '',
                        });
                    }
                });
        }
    }, [accounts, instance, inProgress]);

    React.useEffect(() => {
        if (buttonRef.current) {
            positioningRef.current?.setTarget(buttonRef.current);
        }
    }, [buttonRef, positioningRef]);

    const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSignUp = async (e: React.FormEvent) => {
        console.log('Form submitted:', JSON.stringify(formData));
        e.preventDefault();
        setShowDialog(false); // Hide any dialogs initially

        try {
            registerCustomerAsync()
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, e.g., show error message
        }
    };

    const handleLogin = async () => {
        try {
            const loginResponse = await instance.loginPopup(loginRequest)
        }
        catch (error) {
            setAuthError(authErrorHandling(error));
            console.log('[HandleLogin] Login error:', error);
            setShowDialog(true);
        }
    }

    useEffect(() => {
        console.log('registerCustomerData:', registerCustomerData);
        console.log('serverError:', registerCustomerData?.error);

        if (registerCustomerData && !isLoading && !registerCustomerData?.error) {
            setShowDialog(true);
            return;
        }
        if (registerCustomerData?.error) {
            setShowDialog(true);
            return;
        }
    }, [registerCustomerData, serverError]);

    return (
        <div className={styles.container}>
            {authError && showDialog &&
                <Dialog modalType="alert"
                    open={showDialog}
                    onOpenChange={(event, data) => {
                        setShowDialog(data.open);
                    }}
                >
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Authentication Error</DialogTitle>
                            <DialogContent>
                                {authError}
                            </DialogContent>
                            <DialogActions>
                                <Button appearance="primary" onClick={() => { setShowDialog(false); setAuthError(''); }}>Close</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            }
            {!registerCustomerData?.error && !isLoading && showDialog &&
                <Dialog modalType="alert"
                    open={showDialog}
                    onOpenChange={(event, data) => {
                        setShowDialog(data.open);
                    }}
                >
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Registration complete</DialogTitle>
                            <DialogContent>
                                Your registration has been successfully sent. Please check your e-mail to complete the process.
                            </DialogContent>
                            <DialogActions>
                                <Button appearance="primary" onClick={() => window.location.reload()}>Login</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            }
            {registerCustomerData?.error && !isLoading && showDialog &&
                <Dialog modalType="alert"
                    open={showDialog}
                    onOpenChange={(event, data) => {
                        setShowDialog(data.open);
                    }}
                >
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>{registerCustomerData?.message}</DialogTitle>
                            <DialogContent>
                                {registerCustomerData?.error}
                            </DialogContent>
                            <DialogActions>
                            <Button appearance="primary" onClick={() => { setShowDialog(false); setAuthError(''); }}>Close</Button>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            }
            {accounts[0] ?
                <Card>
                    <form onSubmit={handleSignUp}>
                        <Title1>Register your company</Title1><p />
                        <Subtitle1>Core user details:</Subtitle1><p />
                        <Label>Microsoft Tenant ID</Label>
                        <Input disabled type="text" name="tenantId" value={formData.tenantId} style={{ width: '100%' }} />
                        <Label>Microsoft Tenant Name</Label>
                        <Input disabled type="text" name="tenantName" value={formData.tenantName} style={{ width: '100%' }} />
                        <Label>User Object ID</Label>
                        <Input disabled type="text" name="localAccountId" value={formData.localAccountId} style={{ width: '100%' }} />
                        <Label>User name</Label>
                        <Input disabled type="text" name="userName" value={formData.userName} style={{ width: '100%' }} />
                        <p />
                        <Subtitle1>Company details:</Subtitle1><p />
                        <Label required>Company name</Label>
                        <Input type="text" name="companyDisplayName" value={formData.companyDisplayName} onChange={handleChange} placeholder="Company Name" required style={{ width: '100%' }} />
                        <Label required>Main contact name</Label>
                        <Input type="text" name="contactName" value={formData.contactName} onChange={handleChange} placeholder="Contact Name" required style={{ width: '100%' }} />
                        <Label required>Main contact email</Label>
                        <Input type="email" name="userEmail" value={formData.userEmail} onChange={handleChange} placeholder="Email" required style={{ width: '100%' }} />
                        <Label required>Main contact telephone number</Label>
                        <Input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone Number" required style={{ width: '100%' }} />
                        <Label required>Company address</Label>
                        <Input required type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" style={{ width: '100%' }} />
                        <Label required>Company country</Label>
                        <Select required value={formData.country} onChange={handleChange} name="country" style={{ marginBottom: '20px' }}>
                            <option value="">Select a country</option>
                            {countryOptions.map(([code, name]) => (
                                <option key={code} value={name}>
                                    {name}
                                </option>
                            ))}
                        </Select>
                        <Popover withArrow open={showPopover} positioning={{ positioningRef }}>
                            <PopoverTrigger disableButtonEnhancement>
                                <Button ref={buttonRef} style={{ margin: '10px auto 10px', display: 'block' }} type="submit">Sign up</Button>
                            </PopoverTrigger>
                            <PopoverSurface tabIndex={-1}>
                                <div style={{ padding: '10px' }}> {popOverMessage}</div>
                            </PopoverSurface>
                        </Popover>
                        <Button size="medium" icon={<SignOutRegular />} onClick={handleLogout} >
                            Logout
                        </Button>
                    </form>
                </Card>
                :
                <div>
                    <Title1>Sign up</Title1><p />
                    <Text>Sorry, you need to be signed in to register a new customer.</Text><p />
                    <Button appearance='primary' icon={<PersonAccountsRegular />} onClick={handleLogin} >
                        Login
                    </Button>
                </div>
            }
        </div>
    )
};

export default RegisterNewCustomer;