import { useMsal } from '@azure/msal-react';

export const useGetProfilePic = () => {
    const { accounts, instance } = useMsal();

    const getProfilePic = async () => {
        const silentResult = await instance.acquireTokenSilent({
            scopes: ["User.Read"],
            account: accounts[0]
          });

        const profilePicResponse = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers: {
              Authorization: `Bearer ${silentResult.accessToken}`
            }
          })

        if (profilePicResponse.ok) {
            const profilePicBlob = await profilePicResponse.blob();
            const profilePicUrl = URL.createObjectURL(profilePicBlob);
            return (
                profilePicUrl
            );
        } else {
            const profilePicUrl = 'https://via.placeholder.com/48'; // Default profile picture URL
            return (
                profilePicUrl
            );
        }
    }

    return { getProfilePic };
}

