import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Tab, TabList, TabValue, SelectTabData, SelectTabEvent, Button, Title1 } from '@fluentui/react-components';

import { useSignInState } from './Components/signInState';

import GetUsers from './Components/GetUsers';
import EditUser from './Components/EditUser';
import GetInvitedUsers from './Components/GetInvitedUsers';
import InviteUser from './Components/InviteUser';
import GetCompanyDetails from './Components/GetCompanyDetails';
import FeatureConfiguration from './Components/FeatureConfiguration';
import LicenseDetails from './Components/LicenseAdmin';


const AdminPage: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
    const [inviteUser, setInviteUser] = useState<Boolean>(false);

    const { signInState } = useSignInState();
    const [selectedValue, setSelectedValue] = React.useState<TabValue>('Users');

    const changePage = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedValue(data.value);
    };

    return (
        <div style={{margin: '20px'}}>
            <Title1>Account Admin - {(signInState.customerInfo.find(customer => customer.CustomerID == signInState.currentCustomerID))?.CompanyName}</Title1>
            <TabList size="large" defaultSelectedValue="Users" selectedValue={selectedValue} onTabSelect={changePage}>
                {
                    signInState && signInState.userFeatures.some(feature => feature.FeatureID === 1 && feature.Enabled === true && feature.CustomerID == signInState.currentCustomerID) && (
                        <>
                            <Tab value="Users">Users</Tab>
                            <Tab value="Invites">Invites</Tab>
                        </>
                    )
                }
                {
                    signInState && signInState.userFeatures.some(feature => feature.FeatureID === 2 && feature.Enabled === true && feature.CustomerID == signInState.currentCustomerID) && (
                        <Tab value="CompanyDetails">Company Details</Tab>
                    )
                }
                {
                    <Tab value="Licenses">Licenses</Tab>
                }
                {
                    <Tab value="FeatureConfiguration">Feature Configuration</Tab>
                }
            </TabList>
            <br />
            {
                signInState && signInState.userFeatures.some(feature => feature.FeatureID === 1 && feature.Enabled === true && feature.CustomerID == signInState.currentCustomerID) && (
                    <>
                        {selectedValue === "Users" && (selectedUser ? <EditUser user={selectedUser} customerId={signInState.currentCustomerID} onBack={() => setSelectedUser(null)} /> : <GetUsers onSelectUser={setSelectedUser} CustomerID={signInState.currentCustomerID} />)}
                        {selectedValue === "Invites" && (inviteUser ? <InviteUser CustomerID={signInState.currentCustomerID} onBack={() => setInviteUser(false)} /> : <GetInvitedUsers onInviteUser={setInviteUser} CustomerID={signInState.currentCustomerID} />)}
                    </>
                )
            }
            {
                signInState && signInState.userFeatures.some(feature => feature.FeatureID === 2 && feature.Enabled === true && feature.CustomerID == signInState.currentCustomerID) && (
                    <>
                        {selectedValue === "CompanyDetails" && <GetCompanyDetails CustomerID={signInState.currentCustomerID}/>}
                    </>
                )
            }
            {
                selectedValue === "Licenses" && <LicenseDetails />
            }
            {
                selectedValue === "FeatureConfiguration" && <FeatureConfiguration/>
            }
        </div>
    );
};

export default AdminPage;