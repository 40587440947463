import {
    Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TableColumnSizingOptions,
    Card, CardHeader, Caption1,
    TabList, Tab, SelectTabData, SelectTabEvent, TabValue,
    Text, Button,
    makeStyles, shorthands, tokens
} from "@fluentui/react-components";
import { DeleteRegular, DismissRegular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";

import logo from '../Assets/AppLogo.png';
import { useSignInState } from './signInState';
import useUpdateDatabase from '../Hooks/updateDatabase';

type Items = {
    [key: string]: any;
    InviteID: number,
    CustomerID: number,
    InvitedByUserID: number,
    InvitedByUserEmail: string,
    UserEmail: string,
    CreatedDatetime: Date,
    ExpiryDate: Date,
    AcceptedDatetime: null,
    Token: string,
    Status: string,
    IsValid: boolean
}

type InviteTableProps = {
    invites: Items[];
};

type Filter = {
    [key: string]: string;
};

const useStyles = makeStyles({
    main: {
        ...shorthands.gap("36px"),
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
    },

    card: {
        width: "360px",
        maxWidth: "100%",
        height: "fit-content",
    },

    section: {
        width: "fit-content",
        height: "auto",
    },

    table: {
        maxHeight: '300px',
        overflowY: 'scroll'
    },

    title: {
        ...shorthands.margin(0, 0, "12px"),
    },

    horizontalCardImage: {
        width: "64px",
        height: "64px",
    },

    headerImage: {
        ...shorthands.borderRadius("4px"),
        maxWidth: "44px",
        maxHeight: "44px",
    },

    caption: {
        color: tokens.colorNeutralForeground3,
    },

    text: {
        ...shorthands.margin(0),
    },
});

const InviteTable = ({ invites }: InviteTableProps) => {
    // console.log("[InviteTable]: invites:", invites);
    const styles = useStyles();
    const { signInState } = useSignInState();
    const { updateDatabase, pendingSubmit, submitError, apiSubmitData } = useUpdateDatabase({ scope: 'AdminUser', method: 'PUT' });

    const [selectedRow, setSelectedRow] = useState<Items | null>(null);
    const [items, setItems] = useState<Items[]>(invites);
    const [isDeleteClicked, setIsDeleteClicked] = useState(false);

    const [selectedTabValue, setSelectedTabValue] = useState<TabValue>("Active");
    const [filteredItems, setFilteredItems] = useState<Items[]>([]);

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        setSelectedTabValue(data.value);
    };

    useEffect(() => {
        setItems(invites);
    }, [invites]);

    useEffect(() => {
        let newFilteredItems = items;
        switch (selectedTabValue) {
            case 'Deleted':
                newFilteredItems = items.filter(item => item.Status === 'Deleted' && item.IsValid === false);
                break;
            case 'Accepted':
                newFilteredItems = items.filter(item => item.Status === 'Accepted' && item.IsValid === false);
                break;
            case 'Expired':
                newFilteredItems = items.filter(item => item.Status === 'Expired' && item.IsValid === false);
                break;
            case 'Active':
            default:
                newFilteredItems = items.filter(item => item.IsValid === true);
                break;
            // Add more cases if needed
        }
        setFilteredItems(newFilteredItems);
        setSelectedRow(null);
    }, [selectedTabValue, items]);

    const handleDelete = async (inviteId: number) => {
        try {
            setIsDeleteClicked(true);
            updateDatabase({ url: 'admin/deleteinvite', method: 'PUT', body: { "UserID": signInState.userInfo[0].UserID, "CustomerID": signInState.currentCustomerID, "InviteID": inviteId } });


            // Remove the item from the array
            const updatedItems = items.filter(item => item.InviteID !== inviteId);
            setItems(updatedItems);
        } catch (error) {
            console.error("Failed to delete item", error);
        }
    };

    const handleRowClick = (item: Items) => {
        setIsDeleteClicked(false);
        setSelectedRow(item);
    };

    const columns = [
        { columnKey: 'InvitedByUserEmail', label: 'Invited By' },
        { columnKey: 'UserEmail', label: 'User Email' },
        { columnKey: 'CreatedDatetime', label: 'Created Date' },
        { columnKey: selectedTabValue === 'Accepted' ? 'AcceptedDatetime' : 'ExpiryDate', label: selectedTabValue === 'Accepted' ? 'Accepted Date' : 'Expiry Date' },
        // { columnKey: 'ExpiryDate', label: 'Expiry Date' },
        // { columnKey: 'AcceptedDatetime', label: 'Accepted Date' }, - Need to implement dynamic switching between ExpiryDate and AcceptedDatetime depending on tab
        { columnKey: 'Status', label: 'Status' },
        { columnKey: 'Delete', label: '' },
    ];

    //To implement column resizing options later as it's not easy....
    const [columnSizingOptions] = useState<TableColumnSizingOptions>({
        InvitedByUserEmail: {
            idealWidth: 300,
            minWidth: 170,
            defaultWidth: 260,
        },
        UserEmail: {
            idealWidth: 300,
            minWidth: 170,
            defaultWidth: 260,
        },
        CreatedDatetime: {
            minWidth: 50,
            idealWidth: 120,
            defaultWidth: 110,
        },
        ExpiryDate: {
            minWidth: 50,
            idealWidth: 120,
            defaultWidth: 110,
        },
        AcceptedDatetime: {
            minWidth: 50,
            idealWidth: 120,
            defaultWidth: 110,
        },
        Status: {
            minWidth: 50,
            idealWidth: 120,
            defaultWidth: 110,
        },
    });

    return (
        <>
            <div className={styles.main}>
                <TabList appearance="subtle" selectedValue={selectedTabValue} onTabSelect={onTabSelect}>
                    <Tab value="Active">Active Invites</Tab>
                    <Tab value="Accepted">Accepted Invites</Tab>
                    <Tab value="Expired">Expired Invites</Tab>
                    <Tab value="Deleted">Deleted Invites</Tab>
                </TabList>
                <div className={styles.section}>
                    <div className={styles.table}>
                        <Table sortable arial-label="Invite table" >
                            <TableHeader>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableHeaderCell key={column.columnKey} >
                                            {column.label}
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {filteredItems.map((item) => (
                                    <TableRow key={item.InviteID} onClick={() => handleRowClick(item)}>
                                        {columns.map((column) => (
                                            <TableCell key={column.columnKey}>
                                                <TableCellLayout truncate>
                                                    {column.columnKey === 'Delete'
                                                        ? (item.IsValid && !item.AcceptedDatetime) ? <Button icon={<DeleteRegular />} aria-label="Delete" onClick={(e) => { e.stopPropagation(); handleDelete(item.InviteID); }} /> : null
                                                        : ['CreatedDatetime', 'ExpiryDate', 'AcceptedDatetime'].includes(column.columnKey)
                                                            ? item[column.columnKey]
                                                                ? new Date(item[column.columnKey]).toLocaleDateString()
                                                                : ''
                                                            : item[column.columnKey]
                                                    }
                                                </TableCellLayout>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>


                {selectedRow && !isDeleteClicked && (
                    <section className={styles.section}>
                        <Card>
                            <CardHeader
                                image={
                                    <img
                                        className={styles.headerImage}
                                        src={logo}
                                        alt="App Name Document"
                                    />
                                }
                                header={<Text weight="semibold">User Invite</Text>}
                                description={
                                    <Caption1 className={styles.caption}>{selectedRow.Status}</Caption1>
                                }
                                action={
                                    <Button
                                        appearance="transparent"
                                        icon={<DismissRegular />}
                                        onClick={() => setSelectedRow(null)}
                                        aria-label="Dismiss card"
                                    />
                                }
                            />
                            <>
                                <p>
                                    Invite ID: {selectedRow.InviteID}
                                </p>
                                Invited By: {
                                    selectedRow.InvitingUserDetails.DisplayName ||
                                    ((selectedRow.InvitingUserDetails.FirstName && selectedRow.InvitingUserDetails.LastName) ?
                                        (selectedRow.InvitingUserDetails.FirstName + ' ' + selectedRow.InvitingUserDetails.LastName) : null) ||
                                    selectedRow.InvitedByUserEmail
                                }
                                { } ({selectedRow.InvitedByUserEmail})
                                <br />
                                Sent to: {selectedRow.UserEmail}
                                <br /><br />
                                Created Date: {new Date(selectedRow.CreatedDatetime).toDateString()} at {new Date(selectedRow.CreatedDatetime).toLocaleTimeString()}
                                <br />
                                {
                                    selectedTabValue === 'Deleted'
                                        ? <>Invite would have expired on: {new Date(selectedRow.ExpiryDate).toDateString()}</>
                                        : selectedTabValue === 'Expired'
                                            ? <>Invite expired on: {new Date(selectedRow.ExpiryDate).toDateString()}</>
                                            : selectedTabValue === 'Accepted'
                                                ? selectedRow.AcceptedDatetime
                                                    ? <>Invite was accepted on: {new Date(selectedRow.AcceptedDatetime).toDateString()}</>
                                                    : <>Invite was accepted on: not defined</>
                                                : <>Invite will expire on: {new Date(selectedRow.ExpiryDate).toDateString()}</>
                                }
                            </>
                        </Card>
                    </section>
                )}

            </div>
        </>
    );
}

export default InviteTable;