import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

import SideNav from './SideNav';
import { Header } from './Header';
import MainContent from './MainContent';

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        ...shorthands.padding(
            tokens.spacingVerticalMNudge,
            tokens.spacingHorizontalM,
        ),
        boxSizing: "border-box",
        display: "grid",
        gridTemplateAreas: `
                  "header header header"
                  "nav    main   side"
          `,
        gridTemplateColumns: "auto 1fr auto",
        gridTemplateRows: "auto 1fr",
        height: "100vh",
        rowGap: tokens.spacingVerticalM,
    },
    header: {
        ...shorthands.gridArea("header")
    },
    nav: {
        ...shorthands.gridArea("nav"),
    },
    main: {
        ...shorthands.gridArea("main"),
    }
});

const AppLayout = ({ children }: { children: React.ReactNode }) => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <header className={styles.header}>
                <Header />
            </header>
            <nav className={styles.nav}>
                <SideNav />
            </nav>
            <div className={styles.main}>
                <MainContent>
                    {children}
                </MainContent>
            </div>
        </div>
    );
};

export default AppLayout;