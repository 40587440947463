// App.tsx
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { FluentProvider, webLightTheme, Button } from '@fluentui/react-components';
import { SignOutRegular } from "@fluentui/react-icons";
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { useSignInState } from './Components/signInState';
import { useHandleLogout } from './Components/HandleLogout';
import LoadingPage from './Components/LoadingPage';
import { appTheme } from './Helpers/customRevealTheme';
import { lightPowerONTheme, darkPowerONTheme, brandPowerON } from './Helpers/fluentPowerONTheme';

import AppLayout from './Layout/AppLayout';
import LandingPage from './LandingPage';
import AcceptInvite from './AcceptInvite';
import NotFoundPage from './NotFoundPage';

import { HomePage } from './HomePage';
import IntuneDashboards from './IntuneDashboards';
import CeateDashboard from './CreateDashboards';
import CustomDashboards from './CustomDashboards';
import AdminPage from './AccountAdmin';
import ProfilePage from './ProfilePage';

import SignUpFlow from './SignUpFlow';
import RegisterNewCustomer from './Components/RegisterCustomer';

declare let $: any;

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { signInState } = useSignInState();
  const { handleLogout } = useHandleLogout();
  const [shouldRegister, setShouldRegister] = useState<boolean>(false);
  const [currentCustomerID, setCurrentCustomerID] = useState<number | undefined>();
  const [currentTheme, setCurrentTheme] = useState(lightPowerONTheme);

  useEffect(() => {
    console.log('[App]: signInState', signInState);
    setCurrentCustomerID(signInState.currentCustomerID);
    setCurrentTheme(signInState.userInfo[0]?.ThemePreference === 'dark' ? darkPowerONTheme : lightPowerONTheme);
    //Look at putting Reveal themeing switching here
  }, [signInState]);

  function withAdminPermissions(WrappedComponent: React.ComponentType<any>) {
    return (props: any) => {

      const hasPermission = signInState.userFeatures.some((feature: any) =>
        (feature.FeatureID === 1 || feature.FeatureID === 2) &&
        feature.Enabled === true &&
        feature.CustomerID === signInState.currentCustomerID
      );

      if (!hasPermission) {
        return <Navigate to="/" />;
      }

      return <WrappedComponent {...props} />
    };
  };
  const AdminPageWithPermissions = withAdminPermissions(AdminPage);

  function withLicense(moduleName: string, WrappedComponent: React.ComponentType<any>) {
    return (props: any) => {

      const hasPermission = signInState.licenseInfo.some(license =>
        license.CustomerID === signInState.currentCustomerID &&
        license.IsActive === true &&
        license.modules.some(module => module.name.startsWith(moduleName))
      );

      if (!hasPermission) {
        return <Navigate to="/" />;
      }

      return <WrappedComponent {...props} />
    };
  };

  function withFeature(featureID: number, WrappedComponent: React.ComponentType<any>) {
    return (props: any) => {

      const hasPermission = signInState.userFeatures.some((feature: any) =>
        feature.FeatureID === featureID &&
        feature.Enabled === true &&
        feature.CustomerID === signInState.currentCustomerID
      );

      if (!hasPermission) {
        return <Navigate to="/" />;
      }

      return <WrappedComponent {...props} />
    };
  }

  return (

    <FluentProvider theme={currentTheme}>
      <Router>
        <Routes>
          <Route path="/accept-invite" element={<AcceptInvite />} />
          <Route path="/register" element={<RegisterNewCustomer />} />
          <Route path="/*" element={
            <>
              <UnauthenticatedTemplate>
                {!isAuthenticated && <LandingPage />}
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                {!shouldRegister && !signInState.isAuthed && signInState.isLoading &&
                  <div className="siginwait" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
                    <LoadingPage message="Checking signin details..." />
                    <div className="logoutbutton" style={{ margin: '20px' }}>
                      <Button size="medium" icon={<SignOutRegular />} onClick={handleLogout} >
                        Logout
                      </Button>
                      
                    </div>
                  </div>
                }
                {!shouldRegister && !signInState.isLoading && !signInState.isAuthed && (signInState.userInfo.length === 0 || signInState.customerInfo.length === 0) &&
                  (() => {
                    console.log("No user or customer info found");
                    setShouldRegister(true);
                    return null
                  })()
                }
                {signInState.isAuthed && signInState.userInfo && signInState.customerInfo &&
                  <AppLayout>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/intunedashboards" element={withLicense("Reporting_Intune", IntuneDashboards)({ currentCustomerID: signInState.currentCustomerID })} />
                      <Route path="/createdash" element={withFeature(3, CeateDashboard)({ currentCustomerID: signInState.currentCustomerID })} />
                      <Route path="/customdashboards" element={withLicense("Reporting_", CustomDashboards)({ currentCustomerID: signInState.currentCustomerID })} />
                      <Route path="/admin" element={<AdminPageWithPermissions />} />
                      <Route path="/profile" element={<ProfilePage />} />
                      <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                  </AppLayout>
                }
                {shouldRegister && <SignUpFlow />}
              </AuthenticatedTemplate>
            </>
          } />
        </Routes>
      </Router>
    </FluentProvider>

  );
};

export default App;