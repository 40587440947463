// LandingPage.tsx
import React from 'react';
import { useMsal } from "@azure/msal-react";
import { makeStyles, Button, Title1, Subtitle2, Text, Image } from "@fluentui/react-components";
import { PersonAccountsRegular } from "@fluentui/react-icons";
import { useHandleLogin } from './Components/HandleLogin';
import HeroSection from './Components/HeroSection';

const useStyles = makeStyles({
    image: {
        columnGap: "15px",
        display: "inline-flex",
        justifyContent: "flex-start"
    },
    buttons: {
        columnGap: "15px",
        display: "inline-flex",
        justifyContent: "flex-end",
        alignItems: "center"
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

const LandingPage = () => {
    const { instance } = useMsal();
    const styles = useStyles();
    const { handleLogin } = useHandleLogin();

    return (
        <>
      {/* <HeroSection /> */}
        {/* <div className="hero-page">
            <div className="hero-content" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="hero-image" style={{ marginRight: '1rem' }}>
                    <img src={require('./Assets/backgrounds/background2.png')} alt="Hero" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
                <div className="welcome-message" style={{ margin: '50px' }}>
                    <h1>Welcome!</h1>
                    <p>Please login or sign up to begin.</p>
                    <div className={styles.buttons}>
                        <Button size="medium" icon={<PersonAccountsRegular />} onClick={handleLogin}>
                            Log in
                        </Button>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="hero-page" style={{ 
    backgroundImage: `url(${require('./Assets/backgrounds/background3.png')})`, 
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh' // This will make the div take the full height of the viewport
}}>
    <div className="welcome-message" style={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // This will give a slightly transparent white background
        padding: '50px',
        borderRadius: '10px', // This will make the corners of the box slightly rounded
        textAlign: 'center',
        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.2)' // This will add a drop shadow
    }}>
        <>
        <Image className={styles.image} src={require('./Assets/Logos/LogoBlue128.png')} alt="Additionality Logo" />
        <br/>
        <Title1>Welcome to Additionality!</Title1>
        <br/>
        <Text>Where you can finally report from Intune with ease.</Text>
        </>
         {/*
        <Text>Please login or sign up to begin.</Text> */}
        <p/><br/><p/>
        <div className={styles.buttons}>
            <Button size="medium" appearance="primary" icon={<PersonAccountsRegular />} onClick={handleLogin} >
                Log in
            </Button>
        </div>
        <p/><br/><p/>
        <Subtitle2>06-24 Release - Private Preview</Subtitle2>
    </div>
</div>
        </>
    );
};

export default LandingPage;